import { ref, toRaw, onMounted } from 'vue'
import { showError, showSuccess } from '@/utils'
import { getHints, createHint, updateHint } from '@/api'

export const useHint = function () {
  const hints = ref([])
  const getting = ref(false)
  const showDialog = ref(false)
  const making = ref(false)
  const editingHint = ref({})
  const reference = ref('')
  const regexp = ref('')

  const get = async function () {
    getting.value = true

    try {
      hints.value = await getHints()
    } catch (err) {
      showError(err)
    } finally {
      getting.value = false
    }
  }

  const showCreate = function () {
    showDialog.value = true
    editingHint.value.title = ''
    editingHint.value.references = []
    editingHint.value.regexps = []
    editingHint.value.description = ''
    delete editingHint.value._id
  }

  const showUpdate = function (hint) {
    hint = JSON.parse(JSON.stringify(hint))
    const { _id, title, references, regexps, description } = hint
    showDialog.value = true
    editingHint.value._id = _id
    editingHint.value.title = title
    editingHint.value.references = references
    editingHint.value.regexps = regexps
    editingHint.value.description = description
  }

  const update = async function () {
    making.value = true

    try {
      const id = editingHint.value._id
      const form = toRaw(editingHint.value)
      delete form._id

      await updateHint(id, form)
      showSuccess('更新成功')
    } catch (err) {
      showError(err)
    } finally {
      making.value = false
    }
  }

  const make = async function () {
    making.value = true

    try {
      const form = toRaw(editingHint.value)
      delete form._id
      console.log(form)
      await createHint(form)
      showSuccess('更新成功')
      await get()
      showDialog.value = false
    } catch (err) {
      showError(err)
    } finally {
      making.value = false
    }
  }

  onMounted(get)

  return {
    hints,
    getting,
    get,
    showDialog,
    make,
    making,
    update,
    editingHint,
    showCreate,
    showUpdate,
    reference,
    regexp,
  }
}