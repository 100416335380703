<template>
  <div>
    <h1>网站广播</h1>
    <el-form>
      <el-form-item label="标题">
        <el-input v-model="newMessage.title"></el-input>
      </el-form-item>

      <el-form-item label="内容">
        <el-input v-model="newMessage.content"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="createMessage" :loading="sending">添加</el-button>
      </el-form-item>
    </el-form>
  </div>

  <el-table :data="messages">
    <el-table-column label="_id" prop="_id"></el-table-column>
    <el-table-column label="title" prop="title"></el-table-column>
    <el-table-column label="content" prop="content"></el-table-column>
    <el-table-column label="status" prop="status"></el-table-column>
    <el-table-column label="show">
      <template #default="scope">
        <el-switch
          @change="toggleMessageShow(scope.row)"
          :active-value="scope.row.status !== 'unread'"
          :inactive-value="scope.row.status === 'unread'"></el-switch>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { getMessages, addMessage, updateMessage } from '@/api'
import { onMounted, reactive, ref } from 'vue'
import { showError, showSuccess } from '@/utils'

export default {
  setup () {
    const messages = ref([])
    const newMessage = reactive({
      title: '',
      content: ''
    })
    const sending = ref(false)

    const createMessage = async function () {
      sending.value = true

      try {
        await addMessage(newMessage.title, newMessage.content)
        showSuccess('发送成功')
        newMessage.title = ''
        newMessage.content = ''
        messages.value = await getMessages()
      } catch (err) {
        showError(err)
      } finally {
        sending.value = false
      }
    }

    const toggleMessageShow = async function (message) {
      try {
        const willChangeTo = message.status === 'unread' ? 'deleted' : 'unread'
        await updateMessage(message._id, {
          status: willChangeTo,
        })
        showSuccess('更新成功')
        messages.value = await getMessages()
      } catch (err) {
        showError(err)
      }
    }

    onMounted(async () => {
      messages.value = await getMessages()
    })

    return {
      messages,
      newMessage,
      createMessage,
      sending,
      toggleMessageShow,
    }
  },
}
</script>

<style scoped>

</style>