import { adminapi } from './base'
import reusePromise from 'reuse-promise'
import { showError } from '@/utils'

export const getStaticsCharts = async function ({ startTime, endTime, intervalTag = 1 }) {
  try {
    const res = await adminapi.get('/statistic/chartInfo', {
      params: {
        startTime,
        endTime,
        intervalTag,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getSysArgs = async function () {
  try {
    const res = await adminapi.get('/sysargs/all')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateSysArgs = async function (type, doc) {
  try {
    const res = await adminapi.post('/sysargs/update', {
      type,
      updateDoc: doc
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getMcPing = async function (podId) {
  try {
    const res = await adminapi.get('/pods/mcPing', {
      params: {
        podId,
      }
    })
    return res.data.result.latency
  } catch (err) {
    console.error(err)
    showError(err)
    return 'disconnected'
  }
}

export const getPodInfo = async function (podId, startTime, endTime, intervalTag = 1) {
  try {
    const res = await adminapi.get('/pods/statisticsInfo', {
      params: {
        podId,
        startTime,
        endTime,
        intervalTag,
      }
    })
    return res.data.result
  } catch (err) {
    showError(err)
  }
}

export const getIncomeInfo = async function (startTime, endTime) {
  try {
    const res = await adminapi.get('/statistic/incomeInfo', {
      params: {
        startTime,
        endTime,
      }
    })
    return res.data.result
  } catch (err) {
    showError(err)
  }
}

export const getAllErrors = async function (page = 1) {
  try {
    const res = await adminapi.get('/diagnosis/errAll', {
      params: {
        page,
      }
    })
    return res.data.result
  } catch (err) {
    showError(err)
  }
}

export const settleError = async function (errRecordId) {
  try {
    const res = await adminapi.post('/diagnosis/errSettled', {
      errRecordId,
    })
    return res.data.result
  } catch (err) {
    showError(err)
  }
}

const _getPlatformInfoFactory = function (target = 'nodes') {
  return async function (startTime, endTime, intervalTag = 1) {
    try {
      const res = await adminapi.get('/statistic/platformInfo', {
        params: {
          startTime,
          endTime,
          intervalTag,
          target,
        }
      })
      return res.data.result
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

const _getNodesInfo = _getPlatformInfoFactory('nodes')
const _getNasInfo = _getPlatformInfoFactory('nas')
const _getEcsInfo = _getPlatformInfoFactory('ecss')
const _getOssInfo = _getPlatformInfoFactory('oss')
const _getRoutesInfo = _getPlatformInfoFactory('routes')
const _getDitingsInfo = _getPlatformInfoFactory('ditings')

export const getNodesInfo = reusePromise(_getNodesInfo)
export const getNasInfo = reusePromise(_getNasInfo)
export const getEcsInfo = reusePromise(_getEcsInfo)
export const getOssInfo = reusePromise(_getOssInfo)
export const getRoutesInfo = reusePromise(_getRoutesInfo)
export const getDitingsInfo = reusePromise(_getDitingsInfo)