<template>
  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h1>{{ server.intranetIp }}</h1>
    </el-col>
    <el-col :span="7" :xl="4">
    </el-col>
    <el-col :span="7" :xl="4">
    </el-col>
  </el-row>

  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h3>CPU</h3>
      <Chart :options="getChartOptions(server.datapoints, 'cpuUsage', function () { return `${this.y.toFixed(2)}%` })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>内存</h3>
      <Chart :options="getChartOptions(server.datapoints, 'memoryUsage', function () { return `${this.y.toFixed(2)}%` })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>系统负载</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'systemLoad')"
          />
    </el-col>
  </el-row>

  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h3>公网进</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'netInRate',
          function () { return `${(this.y / 1024).toFixed(2)} Mb/s` })"
          />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>公网出</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'netOutRate',
          function () { return `${(this.y / 1024).toFixed(2)} Mb/s` })"
          />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>磁盘使用率</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'diskUsage',
          function () { return `${this.y.toFixed(2)}%` })"
          />
    </el-col>
  </el-row>

  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h3>IO 读</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'ioReadRate',
          function () { return `${(this.y / 1024).toFixed(2)} Mb/s` })"
          />
    </el-col>
    <el-col :span="7" :xl="4">
      <h3>IO 写</h3>
      <Chart
        :options="getChartOptions(
          server.datapoints,
          'ioWriteRate',
          function () { return `${(this.y / 1024).toFixed(2)} Mb/s` })"
          />
    </el-col>
    <el-col :span="7" :xl="4">
    </el-col>
  </el-row>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { getChartOptionsFactory } from '@/utils'
import { computed } from 'vue'

export default {
  props: {
    server: {
      type: Object,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
    },
    intervalTag: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const getChartOptions = computed(() => {
      return getChartOptionsFactory(props.startTime, props.intervalTag)
    })

    return {
      getChartOptions,
    }
  },
  components: {
    Chart,
  }
}
</script>