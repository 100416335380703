<template>
  <el-tabs v-model="activeName">
    <el-tab-pane name="health" label="Health">
      <Health v-if="activeName === 'health'"></Health>
    </el-tab-pane>

    <el-tab-pane name="metrics" label="Metrics">
      <Metrics v-if="activeName === 'metrics'"></Metrics>
    </el-tab-pane>

    <el-tab-pane name="pods" label="Pods">
      <DevopPod v-if="activeName === 'pods'"></DevopPod>
    </el-tab-pane>

    <el-tab-pane name="broadcast-in-game" label="Console Broadcast">
      <BroadcastInGame v-if="activeName === 'broadcast-in-game'"></BroadcastInGame>
    </el-tab-pane>

    <el-tab-pane name="broadcast-web" label="Web Broadcast">
      <BroadcastWeb v-if="activeName === 'broadcast-web'"></BroadcastWeb>
    </el-tab-pane>

    <el-tab-pane name="schedules" label="Schedules"></el-tab-pane>

    <el-tab-pane name="logs" label="Logs">
      <Log v-if="activeName === 'logs'"></Log>
    </el-tab-pane>

    <el-tab-pane name="anti-ddos" label="Anti-dDos">
      <Ddos v-if="activeName === 'anti-ddos'"></Ddos>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import DevopPod from '@/components/DevopPod'
import { ref } from 'vue'
import BroadcastInGame from '@/components/BroadcastInGame.vue'
import BroadcastWeb from '@/components/BroadcastWeb.vue'
import Ddos from '@/components/Ddos'
import Metrics from '@/components/Metrics'
import Health from '@/components/Health'
import Log from '@/components/Log'

export default {
  setup () {
    const activeName = ref('health')

    return {
      activeName,
    }
  },
  components: {
    DevopPod,
    BroadcastInGame,
    BroadcastWeb,
    Ddos,
    Metrics,
    Health,
    Log,
  }
}
</script>

<style scoped>

</style>