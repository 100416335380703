<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Plans" name="plans">
        <Plan></Plan>
      </el-tab-pane>
      <el-tab-pane label="Softwares" name="softwares">
        <Software></Software>
      </el-tab-pane>
      <el-tab-pane label="Hints" name="hints">
        <Hint></Hint>
      </el-tab-pane>
      <el-tab-pane label="SysArgs" name="sysargs">
        <SysArgs></SysArgs>
      </el-tab-pane>
      <el-tab-pane label="Access Policies" name="accesspolicies">
        <AccessPolicy></AccessPolicy>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Software from '@/components/Software.vue'
import Plan from '@/components/Plan.vue'
import Hint from '@/components/Hint.vue'
import SysArgs from '@/components/SysArgs.vue'
import AccessPolicy  from '@/components/AccessPolicy.vue'

export default {
  setup () {
    
    return {
      activeName: 'plans'
    }
  },
  components: {
    Software,
    Plan,
    Hint,
    SysArgs,
    AccessPolicy,
  }
}
</script>

<style>

</style>