import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { adminapi } from '@/api/base'
import localforage from 'localforage'

import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/display.css'

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')

const tokenSetter = async (config) => {
  const accessToken = await localforage.getItem('pixelcloud-admin-token')
  
  if (accessToken) {
    config.headers.common['Authorization'] = accessToken 
  }

  return config
}

const tokenRemover = async (error) => {
  if (error.response && error.response.data && error.response.data.code === 401) {
    await localforage.removeItem('pixelcloud-admin-token')
    store.commit('update_authenticated', false)
    store.commit('update_token', '')
    router.push('/login')
  }

  throw error
}

adminapi.interceptors.request.use(tokenSetter)
adminapi.interceptors.response.use((response) => {
  return response
}, tokenRemover)