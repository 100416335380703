import { ref, onMounted, reactive, watch, computed } from 'vue'
import { getRedeems, createRedeems, batchUpdateValue } from '@/api'
import { showError, showSuccess } from '@/utils'

export const useRedeem = function () {
  const page = ref(1)
  const redeems = ref([])
  const seeAvailable = ref(true)
  const newRedeems = ref([])
  const showNewRedeems = ref(false)
  const showNewRedeemForm = ref(false)
  const creating = ref(false)
  const batchUpdating = ref(false)
  const oldValue = ref(0)
  const newValue = ref(0)
  const showBatchUpdater = ref(false)
  const newRedeemForm = reactive({
    description: '',
    quantity: 0,
    value: 0,
    type: 'balance_redeem'
  })

  const isOnce = computed({
    set (value) {
      if (value) {
        newRedeemForm.type = 'balance_redeem_once'
      } else {
        newRedeemForm.type = 'balance_redeem'
      }
    },
    get () {
      return newRedeemForm.type === 'balance_redeem_once'
    }
  })

  const statuses = computed(() => {
    if (seeAvailable.value) {
      return ['available']
    }

    return ['unavailable']
  })

  const newRedeemString = computed(() => {
    let str = ''

    newRedeems.value.forEach(redeem => {
      str += (redeem.code + '\n')
    })

    return str
  })

  watch(page, () => {
    get()
  })
  watch(statuses, () => {
    get()
  })

  const get = async function () {
    try {
      redeems.value = await getRedeems(statuses.value, page.value)
    } catch (err) {
      showError(err)
    }
  }

  const create = async function () {
    creating.value = true
    try {
      newRedeems.value = await createRedeems({
        description: newRedeemForm.description,
        quantity: newRedeemForm.quantity,
        value: newRedeemForm.value,
        type: newRedeemForm.type
      })

      showSuccess('创建成功')

      showNewRedeemForm.value = false
      showNewRedeems.value = true
    } catch (err) {
      showError(err)
    } finally {
      creating.value = false
    }
  }

  const updateValues = async function () {
    batchUpdating.value = true
    try {
      await batchUpdateValue(oldValue.value * 100, newValue.value * 100)
      showSuccess('批量修改成功')
      showBatchUpdater.value = false
    } catch (err) {
      showError(err)
    } finally {
      batchUpdating.value = false
    }
  }

  onMounted(get)

  return {
    page,
    newRedeemForm,
    create,
    creating,
    seeAvailable,
    showNewRedeems,
    showNewRedeemForm,
    redeems,
    newRedeemString,
    batchUpdating,
    updateValues,
    oldValue,
    newValue,
    showBatchUpdater,
    isOnce,
  }
}