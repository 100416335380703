<template>
  <el-upload
    :action="''"
    :accept="accept"
    :http-request="uploadRequest"
    :before-upload="beforeUpload"
    :on-error="onError">
    <slot></slot>
    <template #tip>
      <div v-show="tipText.length > 0" class="el-upload__tip">{{ tipText }}</div>
    </template>
  </el-upload>
  <el-progress
    :format="() => ''"
    v-show="showProgress"
    :percentage="progress > 0 ? progress : 40 "
    :indeterminate="progress === 0"></el-progress>
</template>

<script>
import { upload, multipartUpload } from '@/api/us3'
import { getFileSha1, showError } from '@/utils'
import { computed, ref } from 'vue'

export default {
  props: {
    destDir: {
      type: String,
      required: true
    },
    resumable: {
      type: Boolean,
      default: false
    },
    onProgress: {
      type: Function
    },
    onHashProgress: {
      type: Function
    },
    onSuccess: {
      type: Function
    },
    onBeforeUpload: {
      type: Function
    },
    accept: {
      type: String,
      default: '*'
    },
    tipText: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    let filename = ''
    const progress = ref(0)
    const uploading = ref(false)

    const showProgress = computed(() => {
      if (progress.value === 100) {
        return true
      }

      return uploading.value
    })

    const onFinish = function (url) {
      if (props.onSuccess) {
        props.onSuccess(url)
      }
      uploading.value = false
      progress.value = 100
    }

    const onError = function (err) {
      uploading.value = false
      showError(err)
    }

    const uploadRequest = async function (option) {
      try {
        const file = option.file
        let ext = file.name.substr(file.name.indexOf('.'))
        if (['maps', 'outmaps', 'resourcepacks', 'shaderpacks', 'datapacks', 'configpacks'].includes(props.destDir)) {
          ext = '.zip'
        } else if (['mods', 'plugins/spigot', 'plugins/sponge'].includes(props.destDir)) {
          ext = '.jar'
        } else if (['skins'].includes(props.destDir)) {
          ext = '.png'
        } else if (['behaviorpacks'].includes(props.destDir)) {
          ext = '.mcaddon'
        }

        const sha1 = await getFileSha1(file)
        if (props.onHashProgress) {
          props.onHashProgress(sha1)
        }
        filename = `${sha1}${ext}`
        uploading.value = true

        if (props.resumable) {
          const [, url] = await multipartUpload(
            file,
            filename,
            props.destDir,
            function (percentage) {
              if (props.onProgress) {
                props.onProgress(percentage)
              }
              progress.value = percentage * 100
            },
          )

          onFinish(url)
        } else {
          const url = await upload(file, filename, props.destDir)
          onFinish(url)
        }
      } catch (err) {
        showError(err)
      }
    }

    const beforeUpload = props.onBeforeUpload ? props.onBeforeUpload : () => true

    return {
      onError,
      uploadRequest,
      progress,
      beforeUpload,
      showProgress,
    }
  },
}
</script>