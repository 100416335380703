<template>
  <div>
    <el-button @click="showCreate">添加 Plan</el-button>
    <el-table :data="plans">
      <el-table-column label="_id" prop="_id"></el-table-column>
      <el-table-column label="type" prop="type"></el-table-column>
      <el-table-column label="costPerDay">
        <template #default="scope">
          ￥{{ scope.row.costPerDay / 100 }}
        </template>
      </el-table-column>
      <el-table-column label="maxCpu" prop="maxCpu"></el-table-column>
      <el-table-column label="maxMemory" prop="maxMemory"></el-table-column>
      <el-table-column label="hardware" prop="hardware"></el-table-column>
      <el-table-column label="status" prop="status"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="showDialog">
      <el-form>
        <el-form-item v-show="editingPlan._id" label="_id">{{ editingPlan._id }}</el-form-item>
        <el-form-item label="type">
          <el-input v-model="editingPlan.type"></el-input>
        </el-form-item>
        <el-form-item label="costPerDay">
          <el-input type="number" v-model="editingPlan.costPerDay"></el-input>
        </el-form-item>
        <el-form-item label="minCpu">
          <el-input type="number" v-model="editingPlan.minCpu"></el-input>
        </el-form-item>
        <el-form-item label="maxCpu">
          <el-input type="number" v-model="editingPlan.maxCpu"></el-input>
        </el-form-item>
        <el-form-item label="freeNetFlow (GB)">
          <el-input type="number" v-model="editingPlan.freeNetFlow"></el-input>
        </el-form-item>
        <el-form-item label="freeDiskSize (GiB)">
          <el-input type="number" v-model="editingPlan.freeDiskSize"></el-input>
        </el-form-item>
        <el-form-item label="bandwitdh (Mbps)">
          <el-input type="number" v-model="editingPlan.bandwidth"></el-input>
        </el-form-item>
        <el-form-item label="promisedPlayers">
          <el-input type="number" v-model="editingPlan.promisedPlayers"></el-input>
        </el-form-item>
        <el-form-item label="availableNodes">
          <el-tag
            v-for="(node, index) in editingPlan.availableNodes"
            :key="node"
            closable
            @close="editingPlan.availableNodes.splice(index, 1)">{{ node }}</el-tag>

          <br>

          <el-button
            v-for="node in alternativeNodes"
            :key="node"
            size="small"
            @click="editingPlan.availableNodes.push(node)">add {{ node }}</el-button>          
        </el-form-item>
        <el-form-item label="minMemory (Mb)">
          <el-input type="number" v-model="editingPlan.minMemory"></el-input>
        </el-form-item>
        <el-form-item label="maxMemory (Mb)">
          <el-input type="number" v-model="editingPlan.maxMemory"></el-input>
        </el-form-item>
        <el-form-item label="cpuType">
          <el-input type="text" v-model="editingPlan.cpuType"></el-input>
        </el-form-item>
        <el-form-item label="hardware">
          <el-select v-model="editingPlan.hardware">
            <el-option label="basic" value="basic"></el-option>
            <el-option label="boost" value="boost"></el-option>
            <el-option label="boost-i9" value="boost-i9"></el-option>
            <el-option label="7950x" value="7950x"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="status">
          <el-select v-model="editingPlan.status">
            <el-option label="available" value="available"></el-option>
            <el-option label="unavailable" value="unavailable"></el-option>
            <el-option label="testing" value="testing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" @click="update">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { usePlan } from '@/composables'

export default {
  setup () {
    return {
      ...usePlan()
    }    
  },
}
</script>