<template>
  <el-row type="flex" justify="center" align="middle" style="height: 80vh">
    <el-col v-if="!authenticated" :span="6" :xs="24" :md="6" :lg="6">
      <el-card>
        <template #header>
          <div class="card-header">
          <span>Pixelcloud 管理系统</span>
          </div>
        </template>
        <login-form></login-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  setup () {
    const store = useStore()
    const authenticated = computed(() => store.getters.authenticated)

    return {
      authenticated,
    }
  },
  components: {
    LoginForm,
  }
}
</script>