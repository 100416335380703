<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="daterange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select v-model="intervalTag">
          <el-option label="1 分钟" :value="1"></el-option>
          <el-option label="5 分钟" :value="5"></el-option>
          <el-option label="15 分钟" :value="15"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="get" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>

    <h1>Realtime</h1>
    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>在线玩家</h3>
        <Chart :options="getChartOptions(datapoints, 'playerCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>运行服务器数</h3>
        <Chart :options="getChartOptions(datapoints, 'runningCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>付费服务器数</h3>
        <Chart :options="getChartOptions(incomeDatapoints, 'podCount')" />
      </el-col>
    </el-row>

    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>消费</h3>
        <Chart :options="getChartOptions(
          incomeDatapoints,
          'totalIncome',
          function () {
            return `￥${(this.y / 100).toFixed(2)}`
          })" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>人均消费</h3>
        <Chart :options="getChartOptions(
          incomeDatapoints,
          'costPerUser',
          function () {
            return `￥${(this.y / 100).toFixed(2)}`
          })" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>流量消费</h3>
        <Chart :options="getChartOptions(
          incomeDatapoints,
          'netflowTotalIncome',
          function () {
            return `￥${(this.y / 100).toFixed(2)}`
          })" />
      </el-col>
    </el-row>

    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>存储消费</h3>
        <Chart :options="getChartOptions(
          incomeDatapoints,
          'diskSizeTotalIncome',
          function () {
            return `￥${(this.y / 100).toFixed(2)}`
          })" />
      </el-col>
      <el-col :span="7" :xl="4"></el-col>
      <el-col :span="7" :xl="4"></el-col>
    </el-row>

    <h1>Total</h1>
    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>Tiny</h3>
        <Chart :options="getChartOptions(datapoints, 'tinyCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Basic</h3>
        <Chart :options="getChartOptions(datapoints, 'basicCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Intermediate</h3>
        <Chart :options="getChartOptions(datapoints, 'intermediateCount')" />
      </el-col>
    </el-row>

    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>Standard</h3>
        <Chart :options="getChartOptions(datapoints, 'standardCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Boost Mini</h3>
        <Chart :options="getChartOptions(datapoints, 'boostMiniCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Boost</h3>
        <Chart :options="getChartOptions(datapoints, 'boostCount')" />
      </el-col>
    </el-row>

    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>Boost Plus</h3>
        <Chart :options="getChartOptions(datapoints, 'boostPlusCount')" />
      </el-col>
      <el-col :span="7" :xl="4"></el-col>
      <el-col :span="7" :xl="4"></el-col>
    </el-row>

    <el-row justify="center">
      <el-col :span="7" :xl="4">
        <h3>Java Online Servers</h3>
        <Chart :options="getChartOptions(datapoints, 'javaCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Bedrock Online Servers</h3>
        <Chart :options="getChartOptions(datapoints, 'bedrockCount')" />
      </el-col>

      <el-col :span="7" :xl="4">
        <h3>Bungeecord Online Servers</h3>
        <Chart :options="getChartOptions(datapoints, 'bungeecordCount')" />
      </el-col>
    </el-row>

    <h1>Network Traffic</h1>

    <el-row justify="center">
      <el-col :span="21" :xl="12">
        <el-form>
          <el-form-item label="累计出流量最小值（Gb）">
            <el-input type="number" v-model="flowOutFilter[0]"></el-input>
          </el-form-item>

          <el-form-item label="累计出流量最大值（Gb）">
            <el-input type="number" v-model="flowOutFilter[1]"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="getFlowOut" :loading="loading">查询</el-button>
          </el-form-item>
        </el-form>

        <p>结果占比：{{ flowOutPercent }}%</p>

        <el-table :data="flowOutDatas">
          <el-table-column prop="podId"></el-table-column>
          <el-table-column>
            <template #default="scope">
              {{ scope.row.flowOut / (1024 * 1024 * 1024) }}Gb
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { Chart } from 'highcharts-vue'
import { getNodesInfo, getIncomeInfo, getNetworkInfo } from '@/api'
import {
  showError,
  getChartOptionsFactory,
  planMap,
  bedrockSoftwareIds,
  bungeecordSoftwareIds, } from '@/utils'

export default {
  setup () {
    const datas = ref([])
    const loading = ref(false)
    const intervalTag = ref(15)
    const daterange = ref([])
    const incomeDatas = ref([])
    const flowOutFilter = ref([0, 0])
    const flowOutPercent = ref(0)
    const flowOutDatas = ref([])

    const startTime = computed(() => {
      if (daterange.value[0]) {
        return new Date(daterange.value[0]).getTime()
      }

      const date = new Date()
      date.setHours(0, 0, 0, 0)
      return date.getTime() - 3 * 24 * 60 * 60 * 1000
    })

    const endTime = computed(() => {
      if (daterange.value[1]) {
        return new Date(daterange.value[1]).getTime()
      }

      const date = new Date()
      date.setDate(date.getDate() + 1)
      date.setHours(0, 0, 0, 0)
      return date.getTime()
    })

    const getChartOptions = computed(() => {
      return getChartOptionsFactory(startTime.value, intervalTag.value)
    })

    const incomeDatapoints = computed(() => {
      return incomeDatas.value.slice(0).sort((a, b) => {
        if (a.time < b.time) {
          return -1
        } else if (a.time === b.time) {
          return 0
        } else {
          return 1
        }
      }).map(data => {
        const detail = {}
        data.incomeDetail.forEach(income => {
          if (income.type === 'rent_server') {
            detail.rentIncome = income.totalAmount
          } else if (income.type === 'extras_netflow_pay') {
            detail.netflowIncome = income.totalAmount
          } else if (income.type === 'netflow_package') {
            detail.netflowPackageIncome = income.totalAmount
          } else if (income.type === 'extras_disksize_pay') {
            detail.disksizeIncome = income.totalAmount
          } else if (income.type === 'disksize_package') {
            detail.disksizePackageIncome = income.totalAmount
          }
        })

        detail.netflowTotalIncome = 0
        detail.diskSizeTotalIncome = 0

        if (detail.netflowIncome) {
          detail.netflowTotalIncome += detail.netflowIncome
        }

        if (detail.netflowPackageIncome) {
          detail.netflowTotalIncome += detail.netflowPackageIncome
        }

        if (detail.disksizeIncome) {
          detail.diskSizeTotalIncome += detail.disksizeIncome
        }

        if (detail.disksizePackageIncome) {
          detail.diskSizeTotalIncome += detail.disksizePackageIncome
        }

        return {
          createdAt: new Date(data.time),
          totalIncome: data.totalIncome,
          costPerUser: data.costPerUser,
          podCount: data.podCount,
          ...detail,
        }
      })
    })

    const datapoints = computed(() => {
      const arr = []

      datas.value.forEach(data => {
        let playerCount = 0
        let runningCount = 0
        let tinyCount = 0
        let basicCount = 0
        let intermediateCount = 0
        let standardCount = 0
        let boostMiniCount = 0
        let boostCount = 0
        let boostPlusCount = 0
        let javaCount = 0
        let bedrockCount = 0
        let bungeecordCount = 0

        for (const node of data.nodes) {
          for (const info of node.pods.planAggregate) {
            playerCount += info.playerCount

            if (planMap.get(info.planId) === 'Tiny') {
              tinyCount += info.count
            } else if (planMap.get(info.planId) === 'Basic') {
              basicCount += info.count
            } else if (planMap.get(info.planId) === 'Intermediate') {
              intermediateCount += info.count
            } else if (planMap.get(info.planId) === 'Standard') {
              standardCount += info.count
            } else if (planMap.get(info.planId) === 'Boost Mini') {
              boostMiniCount += info.count
            } else if (planMap.get(info.planId) === 'Boost') {
              boostCount += info.count
            } else if (planMap.get(info.planId) === 'Boost Plus') {
              boostPlusCount += info.count
            }
          }

          for (const info of node.pods.statusAggregate) {
            if (info.status === 'running') {
              runningCount += info.count
            }
          }

          for (const info of node.pods.softwareAggregate) {
            if (bedrockSoftwareIds.includes(info.softwareId)) {
              bedrockCount += info.count
            } else if (bungeecordSoftwareIds.includes(info.softwareId)) {
              bungeecordCount += info.count
            } else {
              javaCount += info.count
            }
          }
        }

        arr.push({
          createdAt: data.createdAt,
          playerCount,
          runningCount,
          tinyCount,
          basicCount,
          intermediateCount,
          standardCount,
          boostMiniCount,
          boostCount,
          boostPlusCount,
          javaCount,
          bedrockCount,
          bungeecordCount,
        })
      })

      return arr
    })

    const get = async function () {
      loading.value = true
      try {
        [ datas.value, incomeDatas.value ] = await Promise.all([
          await getNodesInfo(startTime.value, endTime.value, intervalTag.value),
          await getIncomeInfo(startTime.value, endTime.value)
        ])
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const getFlowOut = async function () {
      loading.value = true
      try {
        const [ min, max ] = flowOutFilter.value.map(value => Number(value) * 1024 * 1024 * 1024)
        const { totalSize, filterResults } = await getNetworkInfo(startTime.value, endTime.value, [min, max])
        flowOutPercent.value = filterResults.length * 100 / totalSize
        flowOutDatas.value = filterResults
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(get)

    return {
      intervalTag,
      daterange,
      getChartOptions,
      loading,
      get,
      datapoints,
      incomeDatapoints,
      flowOutFilter,
      getFlowOut,
      flowOutPercent,
      flowOutDatas,
    }
  },
  components: {
    Chart,
  }
}
</script>

<style scoped>

</style>