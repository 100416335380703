import { adminapi } from './base'

export const getRoutes = async function () {
  try {
    const res = await adminapi.get('/routes/all')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateRouteStatus = async function (routeId, status, time) {
  try {
    const data = {
      routeId,
      status,
    }

    if (time) {
      data.time = time
    }

    const res = await adminapi.post('/routes/statusSet', data)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const cancelRouteStatusUpdating = async function (routeId) {
  try {
    const res = await adminapi.delete('/routes/statusSetAlarm', {
      data: {
        routeId,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}