import { UCloudUFile } from './us3/ufile-token'

export const protocol = process.env.NODE_ENV !== 'production' ? 'https://' : 'https://'
export const fullHost = process.env.NODE_ENV !== 'production' ? 'api.pixelcloud.cn' : 'api.pixelcloud.cn'
export const us3Bucket = 'pixelcloud-public-static'
export const us3Url = 'https://pixelcloud-public-static.cn-sh2.ufileos.com'
export const us3PublicToken = 'TOKEN_9504edf1-10fb-4d4f-81c2-819c04ca91b4'
export const us3TokenServerUrl = `${protocol}${fullHost}/v1/oss/authorization`

const us3 = new UCloudUFile(us3Bucket, us3Url, us3PublicToken, null, us3TokenServerUrl)

export const upload = function (file, destFileName, dir = '') {
  return new Promise((resolve, reject) => {
    us3.hitUpload({
      file,
      fileRename: `${dir}/${destFileName}`,
    }, () => {
      resolve(`${us3Url}/${dir}${dir === '' ? '' : '/'}${destFileName}`)
    }, err => {
      if (err && err.ErrMsg) {
        reject(err.ErrMsg)
      }
    })
  })
}

export const multipartUpload = async function (file, destFileName, dir = '', callback) {
  return new Promise((resolve, reject) => {
    us3.sliceUpload({
      file,
      fileRename: `${dir}/${destFileName}`,
    }, result => {
      resolve([
        result,
        `${us3Url}/${dir}${dir === '' ? '' : '/'}${destFileName}`
      ])
    }, err => {
      if (err && err.ErrMsg) {
        reject(err.ErrMsg)
      }
    }, ({ value }) => {
      callback(value)
    })
  })
}