import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://adminapi.pixelcloud.cn/v1/' :
                                                        'https://adminapi.pixelcloud.cn/v1/'

export const adminapi = axios.create({
  baseURL,
  headers: {}
})

export const api = axios.create({
  baseURL: 'https://api.pixelcloud.cn/v1/',
  headers: {}
})