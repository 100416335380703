import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Withdraw from '@/views/Withdraw.vue'
import Pod from '@/views/Pod.vue'
import Modpack from '@/views/Modpack.vue'
import User from '@/views/User.vue'
import Redeem from '@/views/Redeem.vue'
import Login from '@/views/Login.vue'
import Devop from '@/views/Devop.vue'
import Params from '@/views/Params.vue'
import localforage from 'localforage'

const routes = [
  {
    path: '/index',
    name: 'Home',
    component: Home
  },
  {
    path: '/extensions',
    name: 'Extension',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "extension" */ '../views/Extension.vue')
  },
  {
    path: '/modpacks',
    name: 'Modpack',
    component: Modpack
  },
  {
    path: '/withdraws',
    name: 'Withdraw',
    component: Withdraw
  },
  {
    path: '/pods',
    name: 'Pod',
    component: Pod
  },
  {
    path: '/users',
    name: 'User',
    component: User,
  },
  {
    path: '/redeems',
    name: 'Redeem',
    component: Redeem,
  },
  {
    path: '/params',
    name: 'Params',
    component: Params
  },
  {
    path: '/devops',
    name: 'Devop',
    component: Devop,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.path !== '/login') {
    const accessToken = await localforage.getItem('pixelcloud-admin-token')

    if (!accessToken) {
      return '/login'
    }
  }

  return true
})

export default router
