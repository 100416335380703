import { onMounted, ref, watch } from 'vue'
import { getExtensions, updateExtension, updateInstalledExtensions } from '@/api'
import { showError, showSuccess } from '@/utils'

const isId = function (str) {
  return str.match(/^[0-9a-fA-F]{24}$/)
}

export const useExtension = function () {
  const type = ref('mod_forge')
  const supportV = ref('1.12.2')
  const showDialog = ref(false)
  const editingExtension = ref({})
  const extensions = ref([])
  const page = ref(1)
  const query = ref('')
  const extensionIdToBatch = ref('')
  const replacing = ref(false)
  const fetching = ref(false)

  watch(page, () => {
    fetch()
  })

  watch(type, () => {
    page.value = 1
    fetch()
  })

  const edit = function (extension) {
    if (!extension.name) {
      extension.type = type.value
    }
    editingExtension.value = extension
    showDialog.value = true
  }

  const remove = async function (extension) {
    const ok = window.confirm('确定删除吗？')
    if (!ok) {
      return
    }

    try {
      const doc = {
        status: 'deleted'
      }
      await updateExtension(extension._id, doc)
      await fetch()
    } catch (err) {
      console.error(err)
      showError(err)
    }
  }

  const fetch = async function () {
    fetching.value = true
    try {
      if (isId(query.value)) {
        extensions.value = await getExtensions('', page.value, { extensionId: query.value })
      } else {
        extensions.value = await getExtensions(type.value, page.value, { name: query.value })
      }
    } catch (err) {
      console.error(err)
      showError(err)
    } finally {
      fetching.value = false
    }
  }

  const replaceExtension = async function () {
    replacing.value = true
    try {
      await updateInstalledExtensions(extensionIdToBatch.value)
      showSuccess('批量替换成功')
    } catch (err) {
      console.error(err)
      showError(err)
    } finally {
      replacing.value = false
    }
  }

  const togglePublicFactory = function (extension) {
    return async function () {
      extension.updatingPublic = true

      try {
        const doc = {
          public: !extension.public
        }
        await updateExtension(extension._id, doc)
        return true 
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        extension.updatingPublic = false
      }
    }
  }

  onMounted(fetch)

  return {
    extensions,
    type,
    supportV,
    showDialog,
    editingExtension,
    edit,
    remove,
    page,
    query,
    fetch,
    fetching,
    extensionIdToBatch,
    replaceExtension,
    replacing,
    togglePublicFactory,
  }
}