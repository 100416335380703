<template>
  <div></div>

  <el-table :data="routes">
    <el-table-column label="routeId" prop="_id"></el-table-column>
    <el-table-column label="rootDomain" prop="rootDomain"></el-table-column>
    <el-table-column label="subdomain" prop="subdomain"></el-table-column>
    <el-table-column label="bindIp" prop="bindIp"></el-table-column>
    <el-table-column label="dnsRecordId" prop="dnsRecordId"></el-table-column>
    <el-table-column label="type" prop="type"></el-table-column>
    <el-table-column label="status" prop="status"></el-table-column>
    <el-table-column label="equalizerId" prop="equalizerId"></el-table-column>
    <el-table-column label="status set" min-width="190">
      <template #default="scope">
        <el-select
          v-model="nextStatus"
          placeholder="变更状态">
          <el-option label="bad" value="bad"></el-option>
          <el-option label="normal" value="normal"></el-option>
          <el-option label="great" value="great"></el-option>
        </el-select>

        <el-date-picker
          type="datetime"
          format="MM-DD HH:mm"
          v-model="activeDate"
          placeholder="生效时间"></el-date-picker>

        <el-button-group>
          <el-button
            :loading="scope.row.loading"
            @click="updateStatus(scope.row, nextStatus, true)">设置立即生效</el-button>
          <el-button
            :loading="scope.row.loading"
            @click="updateStatus(scope.row, nextStatus)">设置延迟生效</el-button>
        </el-button-group>
      </template>
    </el-table-column>
    <el-table-column label="next status">
      <template #default="scope">
        <div v-if="scope.row.alarmAt !== -1">
          <el-tag>
            {{ scope.row.statusAlarm }}
          </el-tag>
          at
          <el-tag>
            {{ new Date(scope.row.alarmAt * 1000).toLocaleString() }}
          </el-tag>
          <el-button
            :loading="scope.row.loading"
            @click="cancelUpdating(scope.row)">取消预约</el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { onMounted, ref } from 'vue'
import { showError, showSuccess } from '@/utils'
import { getRoutes, updateRouteStatus, cancelRouteStatusUpdating } from '@/api'

export default {
  setup () {
    const routes = ref([])
    const activeDate = ref(new Date())
    const nextStatus = ref('')

    const get = async function () {
      try {
        routes.value = await getRoutes()
      } catch (err) {
        showError(err)
      }
    }

    const updateStatus = async function (route, status, now = false) {
      route.loading = true
      try {
        // time is timestamp in seconds or undefinedss
        const time = now ? undefined : Math.round(activeDate.value.getTime() / 1000)
        await updateRouteStatus(route._id, status, time)
        showSuccess('设置成功，是否生效需要稍后刷新确认')
        await get()
      } catch (err) {
        showError(err)
      } finally {
        route.loading = false
      }
    }

    const cancelUpdating = async function (route) {
      route.loading = true
      try {
        await cancelRouteStatusUpdating(route._id)
        showSuccess('取消成功，是否生效需要稍后刷新确认')
        await get()
      } catch (err) {
        showError(err)
      } finally {
        route.loading = false
      }
    }

    onMounted(get)

    return {
      routes,
      updateStatus,
      activeDate,
      nextStatus,
      cancelUpdating,
    }
  },
}
</script>

<style scoped>

</style>