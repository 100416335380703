<template>
  <div>
    <h1>Drive</h1>

    <div style="margin: 1em;">
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="Number((100 * usage.allocated / usage.capacity).toFixed(2))">
        {{ usage.allocated }}Gb
      </el-progress>
      <el-button
        :loading="loading"
        @click="getLatest">刷新</el-button>
    </div>

    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="daterange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select v-model="intervalTag">
          <el-option label="1 分钟" :value="1"></el-option>
          <el-option label="5 分钟" :value="5"></el-option>
          <el-option label="15 分钟" :value="15"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="get" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>

    <div>

    </div>

    <el-row justify="center">
      <el-col :span="6">
        <Chart :options="getChartOptions(datapoints, 'allocated', function () { return `${this.y}Gb` })" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { getNasInfo } from '@/api'
import { showError, getChartOptionsFactory } from '@/utils'
import { Chart } from 'highcharts-vue'

export default {
  setup () {
    const datas = ref([])
    const loading = ref(false)
    const intervalTag = ref(15)
    const daterange = ref([])
    const usage = reactive({
      capacity: 1,
      allocated: 0,
    })

    const startTime = computed(() => {
      if (daterange.value[0]) {
        return new Date(daterange.value[0]).getTime()
      }

      return new Date().getTime()
    })

    const endTime = computed(() => {
      if (daterange.value[1]) {
        return new Date(daterange.value[1]).getTime()
      }

      return new Date().getTime()
    })

    const getChartOptions = computed(() => {
      return getChartOptionsFactory(startTime.value, intervalTag.value)
    })

    const datapoints = computed(() => {
      return datas.value.map(data => {
        return {
          createdAt: data.createdAt,
          allocated: data.nas ? data.nas.allocated : 0,
        }
      })
    })

    const get = async function () {
      loading.value = true
      try {
        datas.value = await getNasInfo(startTime.value, endTime.value, intervalTag.value)
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const getLatest = async function () {
      loading.value = true
      try {
        const date = new Date()
        const startTime = date.getTime() - 60 * 1000
        const endTime = date.getTime()
        const datas = await getNasInfo(startTime, endTime, 1)
        if (datas.length > 0) {
          const last = datas[datas.length - 1]
          if (last.nas) {
            const { allocated, capacity } = last.nas
            usage.allocated = allocated
            usage.capacity = capacity
          }
        }
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(getLatest)

    return {
      get,
      loading,
      intervalTag,
      getChartOptions,
      datapoints,
      daterange,
      usage,
      getLatest,
    }
  },
  components: {
    Chart,
  }
}
</script>