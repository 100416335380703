import { adminapi } from './base'

export const getMessages = async function () {
  try {
    const res = await adminapi.get('/messages/broadcastAll')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const addMessage = async function (title, content, type = 'broadcast_notification') {
  try {
    const res = await adminapi.put('/messages/create', {
      title,
      content,
      type,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateMessage = async function (messageId, doc) {
  try {
    const res = await adminapi.post('/messages/update', {
      messageId,
      updateDoc: doc,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}