<template>
  <el-form
    v-for="arg in args"
    :key="arg._id">
    <h1>{{ arg.type }}</h1>
    <el-form-item
      v-if="arg.type === 'free_activity'"
      label="days">
      <el-input v-model="arg.days"></el-input>
      <el-button @click="save({ type: arg.type, days: Number(arg.days) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'charge_activity'"
      label="inviRewardRate">
      <el-input
        v-model="arg.inviRewardRate"
        type="number"></el-input>
      <el-button @click="save({ type: arg.type, inviRewardRate: Number(arg.inviRewardRate) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'charge_activity'"
      label="bonusPlans">
      <div
        style="display: block; margin: 1em; padding: 1em; border: 1px solid black;"
        v-for="plan in arg.bonusPlans"
        :key="plan._id">
        充 <el-input v-model="plan.amount"></el-input> 送
        <el-input v-model="plan.bonus"></el-input>
      </div>
      <el-button @click="save({ type: arg.type, bonusPlans: arg.bonusPlans })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'game_domain'"
      label="domain">
      <el-input v-model="arg.domain"></el-input>
      <el-button @click="save({ type: arg.type, domain: arg.domain })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'game_domain'"
      label="dnspodId">
      <el-input v-model="arg.dnspodId"></el-input>
      <el-button @click="save({ type: arg.type, dnspodId: arg.dnspodId })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="suspendIn">
      <el-input v-model="arg.suspendIn" type="number"></el-input>
      <el-button @click="save({ type: arg.type, suspendIn: Number(arg.suspendIn) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="diskExceedPrice（元/Gb/天）">
      <el-input v-model="arg.diskExceedPrice" type="number"></el-input>
      <el-button @click="save({ type: arg.type, diskExceedPrice: Number(arg.diskExceedPrice) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="diskPackagePrice（元/Gb/月）">
      <el-input v-model="arg.diskPackagePrice" type="number"></el-input>
      <el-button @click="save({ type: arg.type, diskPackagePrice: Number(arg.diskPackagePrice) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="netflowExceedPrice（元/Gb）">
      <el-input v-model="arg.netflowExceedPrice" type="number"></el-input>
      <el-button @click="save({ type: arg.type, netflowExceedPrice: Number(arg.netflowExceedPrice) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="netflowPackagePrice（元/Gb）">
      <el-input v-model="arg.netflowPackagePrice" type="number"></el-input>
      <el-button @click="save({ type: arg.type, netflowPackagePrice: Number(arg.netflowPackagePrice) })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="extrasNodePortDomain">
      <el-input v-model="arg.extrasNodePortDomain"></el-input>
      <el-button @click="save({ type: arg.type, extrasNodePortDomain: arg.extrasNodePortDomain })" :loading="loading">保存</el-button>
    </el-form-item>

    <el-form-item
      v-if="arg.type === 'general'"
      label="netFlowAccumulateMaxDays">
      <el-input v-model="arg.netFlowAccumulateMaxDays"></el-input>
      <el-button @click="save({ type: arg.type, netFlowAccumulateMaxDays: arg.netFlowAccumulateMaxDays })" :loading="loading">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getSysArgs, updateSysArgs } from '@/api'
import { onMounted, ref } from 'vue'
import { showError, showSuccess } from '@/utils'

export default {
  setup () {
    const loading = ref(false)
    const args = ref([])

    const get = async function () {
      try {
        args.value = await getSysArgs()
      } catch (err) {
        showError(err)
      }
    }

    const save = async function (doc) {
      loading.value = true

      try {
        await updateSysArgs(doc.type, doc)
        showSuccess('成功')
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(()=> {
      get()
    })

    return {
      args,
      loading,
      save,
    }
  }
}
</script>

<style>

</style>