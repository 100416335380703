<template>
  <div>
    <h1>Servers</h1>

    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="daterange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select v-model="intervalTag">
          <el-option label="1 分钟" :value="1"></el-option>
          <el-option label="5 分钟" :value="5"></el-option>
          <el-option label="15 分钟" :value="15"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="get" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>

    <el-tabs tab-position="left" v-model="tab">
      <el-tab-pane
        label="overview"
        name="overview">
        <HealthServerOverview />
      </el-tab-pane>

      <el-tab-pane
        v-for="server in servers"
        :key="server.instanceId"
        :label="server.hostname"
        :name="server.instanceId">
        <HealthServerDetail
          :server="server"
          :start-time="startTime"
          :interval-tag="intervalTag"
          v-if="tab === server.instanceId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { getEcsInfo } from '@/api'
import { showError, parseToServers } from '@/utils'
import HealthServerDetail from './HealthServerDetail.vue'
import HealthServerOverview from './HealthServerOverview.vue'

export default {
  setup () {
    const datas = ref([])
    const tab = ref('overview')
    const loading = ref(false)
    const intervalTag = ref(1)
    const daterange = ref([])

    const startTime = computed(() => {
      if (daterange.value[0]) {
        return new Date(daterange.value[0]).getTime()
      }

      return new Date().getTime() - 2 * 60 * 60 * 1000
    })

    const endTime = computed(() => {
      if (daterange.value[1]) {
        return new Date(daterange.value[1]).getTime()
      }

      return new Date().getTime()
    })

    const servers = computed(() => {
      return parseToServers(datas.value).sort((cur, next) => {
        if (cur.hostname < next.hostname) { return -1 }
        if (cur.hostname > next.hostname) { return 1 }
        return 0
      })
    })

    const get = async function () {
      loading.value = true
      try {
        datas.value = await getEcsInfo(startTime.value, endTime.value, intervalTag.value)
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      get()
    })

    return {
      servers,
      get,
      loading,
      intervalTag,
      daterange,
      tab,
      startTime,
    }
  },
  components: {
    HealthServerDetail,
    HealthServerOverview,
  }
}
</script>