import { adminapi } from './base'

export const getWithdraws = async function (page = 1) {
  try {
    const res = await adminapi.get('/withdraws/all', {
      params: {
        page,
        status: 'created'
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const setWithdrawStatus = async function (withdrawId, status) {
  try {
    const res = await adminapi.post('/withdraws/setStatus', {
      withdrawId,
      status
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}