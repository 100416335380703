import { ref, onMounted } from 'vue'
import { showError, showSuccess } from '@/utils'
import { getSoftwares, updateSoftware, createSoftware } from '@/api'

const javaEnvs = [
  'java_8',
  'java_11',
  'java_17',
  'java_21',
  'java_8_H',
  'java_11_H',
  'java_17_H',
  'java_21_H',
]

const flags = [
  'bedrock',
  'bds',
  'nukkit',
  'liteloader',
  'bungeecord',
  'fabric',
  'without_java',
  'dummy_jar',
  'wine64',
]

export const useSoftware = function () {
  const softwares = ref([])
  const loading = ref(false)
  const editingSoftware = ref({})
  const showDialog = ref(false)
  const flag = ref('')
  const startArg = ref('')
  const coreFile = ref('')
  const secondaryFile = ref('')
  const executableFile = ref('')

  const get = async function () {
    try {
      softwares.value = await getSoftwares()
    } catch (err) {
      showError(err)
    }
  }

  const update = async function () {
    loading.value = true

    try {
      await updateSoftware(editingSoftware.value._id, {
        type: editingSoftware.value.type,
        version: editingSoftware.value.version,
        subversion: editingSoftware.value.subversion,
        image: editingSoftware.value.image,
        coreHash: editingSoftware.value.coreHash,
        coreDownloadUrl: editingSoftware.value.coreDownloadUrl,
        status: editingSoftware.value.status,
        isBedrock: editingSoftware.value.isBedrock,
        javaEnv: editingSoftware.value.javaEnv,
        entryFile: editingSoftware.value.entryFile,
        flags: editingSoftware.value.flags,
        startArgs: editingSoftware.value.startArgs,
        coreFiles: editingSoftware.value.coreFiles,
        secondaryFiles: editingSoftware.value.secondaryFiles,
        executableFiles: editingSoftware.value.executableFiles,
      })
      showDialog.value = false
      showSuccess('编辑成功')
      await get()
    } catch (err) {
      showError(err)
    } finally {
      loading.value = false
    }
  }

  const create = async function () {
    loading.value = true

    try {
      delete editingSoftware.value._id

      await createSoftware(editingSoftware.value)
      showDialog.value = false
      showSuccess('创建成功')
    } catch (err) {
      showError(err)
    } finally {
      loading.value = false
    }
  }

  const edit = function (item) {
    if (!item) {
      item = {
        flags: [],
        startArgs: [],
        coreFiles: [],
        secondaryFiles: [],
        executableFiles: [],
      }
    }
    editingSoftware.value = Object.assign({}, item)
    showDialog.value = true
  }

  const handleClose = function (arr, index) {
    arr.splice(index, 1)
  }

  onMounted(get)

  return {
    softwares,
    get,
    editingSoftware,
    showDialog,
    update,
    edit,
    loading,
    create,
    javaEnvs,
    flags,
    handleClose,
    flag,
    startArg,
    coreFile,
    secondaryFile,
    executableFile,
  }
}