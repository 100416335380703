<template>
  <div>
    <h1>控制台广播</h1>
    <el-form>
      <el-form-item label="content">
        <el-input v-model="content"></el-input>
      </el-form-item>

      <el-form-item label="toAll">
        <el-switch v-model="toAll"></el-switch>
      </el-form-item>

      <el-form-item v-show="!toAll" label="receiver">
        <el-tag
          v-for="(podId, $index) in podIds"
          :key="podId"
          closable
          @close="podIds.splice($index, 1)">{{ podId }}</el-tag>
        
        <el-input v-model="newPodId"></el-input>
        <el-button @click="podIds.push(newPodId)">添加</el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="send" :loading="sending">发送</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { broadcastInGame } from '@/api'
import { ref, watch } from 'vue'
import { showError, showSuccess } from '@/utils'

export default {
  setup () {
    const toAll = ref(true)
    const content = ref('')
    const podIds = ref([])
    const newPodId = ref('')
    const sending = ref(false)

    watch(toAll, () => {
      if (toAll.value) {
        podIds.value = []
      }
    })

    const send = async function () {
      sending.value = true
      
      try {
        await broadcastInGame(content.value, podIds.value)
        showSuccess('发送成功')
        content.value = ''
      } catch (err) {
        showError(err)
      } finally {
        sending.value = false
      }
    }

    return {
      toAll,
      content,
      podIds,
      newPodId,
      send,
      sending,
    }
  },
}
</script>

<style scoped>

</style>