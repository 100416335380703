<template>
  <el-form :inline="true">
    <el-form-item>
      <h2>{{ podId }}</h2>
    </el-form-item>

    <el-form-item>
      <el-date-picker
        v-model="daterange"
        type="datetimerange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date">
      </el-date-picker>
    </el-form-item>

    <el-form-item>
      <el-select v-model="intervalTag">
        <el-option label="1 分钟" :value="1"></el-option>
        <el-option label="5 分钟" :value="5"></el-option>
        <el-option label="15 分钟" :value="15"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button @click="get" :loading="loading">查询</el-button>
    </el-form-item>
  </el-form>

  <el-row justify="center">
    <el-col :span="22">
      <h2>在线玩家</h2>
      <Chart :options="getChartOptions(datapoints, 'playerCount')" />
    </el-col>

    <el-col :span="22">
      <h2>CPU</h2>
      <Chart :options="getChartOptions(datapoints, 'cpuPercent')" />
    </el-col>

    <el-col :span="22">
      <h2>内存</h2>
      <Chart :options="getChartOptions(
        datapoints,
        'memoryUsed',
        function () { return `${(this.y / (1024 * 1024 * 1024)).toFixed(2)}Gb` })" />
    </el-col>

    <el-col :span="22">
      <h2>进流量</h2>
      <Chart :options="getChartOptions(
        datapoints,
        'netFlowIn',
        function () { return `${(this.y / (1024 * 1024 * 1024)).toFixed(2)}Gb` })" />
    </el-col>

    <el-col :span="22">
      <h2>出流量</h2>
      <Chart :options="getChartOptions(
        datapoints,
        'netFlowOut',
        function () { return `${(this.y / (1024 * 1024 * 1024)).toFixed(2)}Gb` })" />
    </el-col>
  </el-row>
</template>

<script>
import { getPodInfo } from '@/api'
import { onMounted, ref, computed } from 'vue'
import { showError, getChartOptionsFactory } from '@/utils'
import { Chart } from 'highcharts-vue'

export default {
  props: {
    podId: {
      type: String,
      required: true,
    }
  },
  setup (props) {
    const datas = ref([])
    const loading = ref(false)
    const intervalTag = ref(1)
    const daterange = ref([])

    const startTime = computed(() => {
      if (daterange.value[0]) {
        return new Date(daterange.value[0]).getTime()
      }

      return new Date().getTime() - 30 * 60 * 60 * 1000
    })

    const endTime = computed(() => {
      if (daterange.value[1]) {
        return new Date(daterange.value[1]).getTime()
      }

      return new Date().getTime()
    })

    const getChartOptions = computed(() => {
      return getChartOptionsFactory(startTime.value, intervalTag.value)
    })

    const datapoints = computed(() => {
      const arr = []

      for (const key of Object.keys(datas.value)) {
        if (key === '_id') {
          continue
        }

        const couples = datas.value[key]
        for (const couple of couples.values()) {
          const [data, timestamp] = couple
          const info = {
            createdAt: timestamp,
          }
          info[key] = data

          let inserted = false

          for (const oldInfo of arr.values()) {
            if (info.createdAt === oldInfo.createdAt) {
              oldInfo[key] = info[key]
              inserted = true
              break
            }
          }

          if (!inserted) {
            arr.push(info) 
          }
        }
      }

      return arr
    })

    const get = async function () {
      loading.value = true
      try {
        datas.value = await getPodInfo(props.podId, startTime.value, endTime.value, intervalTag.value)
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(get)

    return {
      loading,
      daterange,
      intervalTag,
      get,
      datapoints,
      getChartOptions,
    }
  },
  components: {
    Chart,
  }
}
</script>