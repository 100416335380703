import { adminapi } from './base'

export const getModpacks = async function ( statuses = ['available', 'deleted', 'pending'], isPublic = true, page = 1) {
  try {
    const res = await adminapi.get('/modpacks/all', {
      params: {
        page,
        statuses: JSON.stringify(statuses),
        isPublic,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateModpack = async function (modpackId, doc) {
  try {
    const copiedDoc = JSON.parse(JSON.stringify(doc))
    delete copiedDoc._id
    delete copiedDoc.__v
    delete copiedDoc.updatedAt
    delete copiedDoc.createdAt
    if (copiedDoc.dependencies) {
      copiedDoc.dependencies = copiedDoc.dependencies.map(({ _id }) => _id)      
    }
    const res = await adminapi.post('/modpacks/update', {
      updateDoc: copiedDoc,
      modPackId: modpackId,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createModpack = async function (doc) {
  try {
    const copiedDoc = JSON.parse(JSON.stringify(doc))
    const res = await adminapi.put('/modpacks/create', copiedDoc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const parseServerpack = async function ({ downloadUrl, name, description, softwareId }) {
  try {
    const res = await adminapi.put('/tasks/serverpackparse', {
      downloadUrl,
      name,
      description,
      softwareId,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}