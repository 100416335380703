import { onMounted, ref, watch, reactive, computed } from 'vue'
import { getModpacks, updateModpack, parseServerpack, getSoftwares } from '@/api'
import { showError, showSuccess } from '@/utils'
import { useTask } from './task'

export const useModpack = function () {
  const showDialog = ref(false)
  const editingModpack = ref({})
  const modpacks = ref([])
  const page = ref(1)
  const serverpackUrl = ref('')
  const parsing = ref(false)
  const softwares = ref([])
  const softwareId = ref('')
  const showUpload = ref(false)
  const showDeleted = ref(false)
  const showPublic = ref(true)
  const uploadModpack = reactive({
    name: '',
    description: '',
    downloadUrl: '',
    softwareId: '',
    launcherUrl: '',
  })
  const { addTask } = useTask()

  const statuses = computed(() => {
    if (showDeleted.value) {
      return ['available', 'deleted', 'pending']
    }

    return ['available']
  })

  watch(showDeleted, () => {
    page.value = 1
    fetch()
  })

  watch(showPublic, () => {
    page.value = 1
    fetch()
  })

  watch(page, () => {
    fetch()
  })

  const edit = function (modpack) {
    editingModpack.value = modpack
    showDialog.value = true
  }

  const remove = async function (modpack) {
    const ok = window.confirm('确定删除吗？')
    if (!ok) {
      return
    }

    try {
      const doc = Object.assign({}, modpack)
      delete doc._id
      doc.status = 'deleted'
      await updateModpack(modpack._id, doc)
      await fetch()
    } catch (err) {
      console.error(err)
      showError(err)
    }
  }

  const fetch = async function () {
    try {
      modpacks.value = await getModpacks(statuses.value, showPublic.value, page.value)
    } catch (err) {
      console.error(err)
      showError(err)
    }
  }

  const togglePublicFactory = function (modpack) {
    return async function () {
      modpack.updatingPublic = true

      try {
        const doc = {
          public: !modpack.public
        }
        await updateModpack(modpack._id, doc)
        return true 
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        modpack.updatingPublic = false
      }
    }
  }

  const onUploaded = async function (url) {
    console.log(url)
    uploadModpack.downloadUrl = url
  }

  const parse = async function () {
    try {
      if (serverpackUrl.value !== '') {
        throw new Error('please upload a serverpack')
      }

      parsing.value = true
      const data = JSON.parse(JSON.stringify(uploadModpack))
      console.log(data)
      const task = await parseServerpack({
        ...data
      })

      addTask(task, (err, result) => {
        parsing.value = false

        if (err) {
          return showError(err)
        }
        console.log(result)
        showSuccess('添加成功')
        showUpload.value = false
      })
    } catch (err) {
      showError(err)
    }
  }

  onMounted(async () => {
    await fetch()
    softwares.value = await getSoftwares()
  })

  return {
    modpacks,
    showDialog,
    editingModpack,
    edit,
    remove,
    page,
    fetch,
    parsing,
    parse,
    onUploaded,
    softwares,
    softwareId,
    showUpload,
    uploadModpack,
    showDeleted,
    togglePublicFactory,
    showPublic,
  }
}