import { adminapi } from './base'

export const getPlans = async function () {
  try {
    const res = await adminapi.get(`/plans/all`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updatePlan = async function (planId, doc) {
  try {
    const copiedDoc = JSON.parse(JSON.stringify(doc))
    delete copiedDoc._id
    delete copiedDoc.__v
    delete copiedDoc.updatedAt
    delete copiedDoc.createdAt
    const res = await adminapi.post('/plans/update', {
      updateDoc: copiedDoc,
      planId,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createPlan = async function (doc) {
  try {
    const res = await adminapi.put('/plans/create', {
      ...doc
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}