import { adminapi } from './base'

export const login = async function (username, password) {
  try {
    const res = await adminapi.post('/login', {
      username,
      password
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUsers = async function ({ page = 1, nickname, email, userId }) {
  let query = `page=${page}`

  if (nickname) {
    query += `&nickname=${nickname}`
  }

  if (email) {
    query += `&email=${email}`
  }

  if (userId) {
    query += `&userId=${userId}`
  }
  
  try {
    const res = await adminapi.get(`/users/search?${query}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateUser = async function (userId, doc) {
  try {
    const res = await adminapi.post('/users/update', {
      userId,
      updateDoc: doc
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateBalance = async function (userId, amount, withdrawable = true) {
  try {
    const res = await adminapi.post('/users/updateBalance', {
      userId,
      amount,
      withdrawable,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getUserRefund = async function (userId) {
  try {
    const res = await adminapi.get('/users/refundAmount', {
      params: {
        userId,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}