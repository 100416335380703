<template>
  <div class="modpacks">
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-form>
          <el-form-item label="显示所有">
            <el-switch v-model="showDeleted"></el-switch>
          </el-form-item>
          <el-form-item label="显示 public">
            <el-switch v-model="showPublic"></el-switch>
          </el-form-item>
        </el-form>
        <el-button class="add" @click="edit({})">手动新增</el-button>
        <el-button class="add" @click="showUpload = true">上传</el-button>

        <el-table :data="modpacks">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="版本" prop="version"></el-table-column>
          <el-table-column label="status" prop="status"></el-table-column>
          <el-table-column label="description">
            <template #default="scope">
              {{ scope.row.description.substr(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column label="public">
            <template #default="scope">
              <el-switch
                v-model="scope.row.public"
                :loading="scope.row.updatingPublic"
                :before-change="togglePublicFactory(scope.row)"
                ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="110">
            <template #default="scope">
              <el-button-group>
                <el-button @click="edit(scope.row)">编辑</el-button>
                <el-button type="danger" @click="remove(scope.row)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          v-model:currentPage="page"
          layout="prev, pager, next"
          :page-size="10"
          @prev-click="page--"
          @next-click="page++"
          :total="1000">
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog :title="editingModpack.name" v-model="showDialog">
      <modpack-form :softwares="softwares" :modpack="editingModpack"></modpack-form>
    </el-dialog>

    <el-dialog v-model="showUpload">
      <el-form>
        <el-form-item label="name">
          <el-input v-model="uploadModpack.name"></el-input>
        </el-form-item>

        <el-form-item label="description">
          <el-input v-model="uploadModpack.description"></el-input>
        </el-form-item>

        <el-form-item label="software">
          <el-select v-model="uploadModpack.softwareId">
            <el-option
              v-for="software in softwares"
              :key="software._id"
              :label="`${software.type}-${software.version}`"
              :value="software._id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="launcherUrl">
          <el-input type="url" v-model="uploadModpack.launcherUrl"></el-input>
        </el-form-item>

        <el-form-item label="download url">
          <el-input type="url" v-model="uploadModpack.downloadUrl"></el-input>
        </el-form-item>

        <el-form-item>
          <Uploader
            :on-success="onUploaded"
            :resumable="true"
            :dest-dir="'modpacks'">
            <el-button size="mini">上传 Serverpack</el-button>
          </Uploader>
        </el-form-item>

        <el-form-item>
          <el-button :loading="parsing" @click="parse">Parse Serverpack</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ModpackForm from '@/components/ModpackForm.vue'
import { useModpack } from '@/composables'
import Uploader from '@/components/Uploader'

export default {
  setup () {
    return {
      ...useModpack()
    }
  },
  components: {
    ModpackForm,
    Uploader,
  }
}
</script>

<style scoped>
.el-select {
  width: 180px;
}

.add {
  margin-top: 1em;
  float: right;
}

.batch-actions {
  margin-bottom: 1em;
}
</style>