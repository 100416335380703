import { adminapi } from './base'

export const getAllTasks = async function () {
  try {
    const res = await adminapi.get('/tasks/all')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const removeTask = async function (taskId) {
  try {
    const res = await adminapi.post('/tasks/remove', {
      taskId,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}