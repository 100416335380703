<template>
  <div class="container">
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-input
          v-model="keyword"
          placeholder="keyword"
          @keyup.enter="fetchUsers">
          <template #prepend>
            <el-select v-model="searchType" placeholder="搜索类型">
              <el-option label="_id" value="userId"></el-option>
              <el-option label="nickname" value="nickname"></el-option>
              <el-option label="email" value="email"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button :icon="Search" @click="fetchUsers"></el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-table :data="users">
          <el-table-column label="userId" prop="_id"></el-table-column>
          <el-table-column label="nickname" prop="nickname"></el-table-column>
          <el-table-column label="email" prop="email"></el-table-column>
          <el-table-column label="operation">
            <template #default="scope">
              <el-button @click="showDialog(scope.row)">Manage</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="dialogVisible" v-if="editingUser._id">
    <el-form>
      <el-form-item label="_id">{{ editingUser._id }}</el-form-item>
      <el-form-item label="nickname">
        <el-input v-model="editingUser.nickname"></el-input>
      </el-form-item>
      <el-form-item label="email">
        <el-input v-model="editingUser.email"></el-input>
      </el-form-item>
      <el-form-item label="gender">
        <el-select v-model="editingUser.gender">
          <el-option label="male" value="male"></el-option>
          <el-option label="female" value="female"></el-option>
          <el-option label="unknown" value="unknown"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="emailVerified">
        <el-switch v-model="editingUser.emailVerified"></el-switch>
      </el-form-item>
      <el-form-item label="余额">
        ￥{{ editingUser.balance / 100 }}
      </el-form-item>
      <el-form-item label="增加余额是否可提现">
        <el-switch v-model="withdrawable"></el-switch>
      </el-form-item>
      <el-form-item label="增减余额">
        <el-input-number
          v-model="balance"
          :min="0">
        </el-input-number>
        <el-button
          :loading="updatingBalance"
          @click="updateAmount(balance * 100)"
          size="mini">添加</el-button>
        <el-button
          :loading="updatingBalance"
          @click="updateAmount(-1 * balance * 100)"
          size="mini">减少</el-button>
      </el-form-item>
      <el-form-item label="收入">
        ￥{{ editingUser.income / 100 }}
      </el-form-item>

      <el-form-item>
        <el-button :loading="updatingUser" @click="update">更新</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { useUser } from '@/composables'

export default {
  setup () {
    return {
      ...useUser()
    }
  },
}
</script>

<style scoped>
.el-select {
  width: 180px;
}
</style>