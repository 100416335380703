<template>
  <PodManager></PodManager>
</template>

<script>
import PodManager from '@/components/PodManager'

export default {
  setup () {

  },
  components: {
    PodManager,
  }
}
</script>

<style scoped>

</style>