<template>
  <div>
    <el-form>
      <el-form-item label="name">
        <el-input v-model="name"></el-input>
      </el-form-item>

      <el-form-item label="nameCN">
        <el-input v-model="nameCN"></el-input>
      </el-form-item>

      <el-form-item label="authorities">
        <el-tag
          v-for="(perm, $index) in authorities"
          :key="perm"
          closable
          @close="authorities.splice($index, 1)">{{ perm }}</el-tag>

        <el-select v-model="authority">
          <el-option
            v-for="([key, value]) in permissionsMap.entries()"
            :key="key"
            :label="value.name"
            :value="value.path"></el-option>
        </el-select>

        <el-button size="small" @click="authorities.push(authority)">添加</el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="add" :loading="loading">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="policies">
      <el-table-column label="name">
        <template #default="scope">
          <el-input v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="nameCN">
        <template #default="scope">
          <el-input v-model="scope.row.nameCN"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="authorities">
        <template #default="scope">
          <el-tag
            v-for="(permission, $index) in scope.row.authorities"
            :key="permission"
            closable
            @close="scope.row.authorities.splice($index, 1)">{{ permission }}</el-tag>

          <el-select v-model="authority">
            <el-option
              v-for="([key, value]) in permissionsMap.entries()"
              :key="key"
              :label="value.name"
              :value="value.path"></el-option>
          </el-select>

          <el-button size="small" @click="scope.row.authorities.push(authority)">添加</el-button>
        </template>
      </el-table-column>
      <el-table-column label="status">
        <template #default="scope">
          <el-select v-model="scope.row.status">
            <el-option
              label="available"
              value="available"></el-option>
            <el-option
              label="unavailable"
              value="unavailable"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="action">
        <template #default="scope">
          <el-button :loading="loading" @click="update(scope.row)">更新</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { addAccessPolicy, getAccessPolicies, updateAccessPolicy } from '@/api'
import { showError, callNameMap, showSuccess } from '@/utils'

const rpcActionPermission = [
  'start_server', 'stop_server', 'async_backup_world_v2',
  'async_download_world_v2', 'add_extensions_v3', 'remove_extensions_v2',
  'ls_folder_filtered', 'create_folder', 'get_mc_properties',
  'set_mc_properties', 'read_file', 'write_file',
  'copy_file', 'move_file', 'remove_file',
  'yaml_get', 'config_yaml_get', 'yaml_set',
  'async_download_file', 'async_unzip_file',
  'zip_folder', 'get_file_download_url', 'get_start_args',
  'set_additional_args', 'open_mc_cli', 'dusage_file',
  'cli_ping', 'set_pod_status_listener', 'get_mc_core_md5',
  'get_server_start_clistr', 'cli_write',
]

const httpActionPermissions = {
  '/v1/pods/whitelist/singleto': '添加白名单',
  '/v1/pods/whitelist/singlefrom': '移除白名单',
  '/v1/backups/all': '查看备份',
}

const permissionsMap = new Map()

rpcActionPermission.forEach(permissionName => {
  const permission = {}
  permission.path = permissionName
  permission.name = callNameMap.get(permissionName)
  permissionsMap.set(permission.path, permission)
})

for (const key of Object.keys(httpActionPermissions)) {
  const name = httpActionPermissions[key]
  const permission = {}
  permission.name = name
  permission.path = key
  permissionsMap.set(permission.path, permission)
}

export default {
  setup () {
    const loading = ref(false)
    const policies = ref([])
    const name = ref('')
    const nameCN = ref('')
    const authority = ref('')
    const authorities = ref([])

    const get = async function () {
      try {
        loading.value = true
        policies.value = await getAccessPolicies()
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const update = async function (policy) {
      try {
        loading.value = true
        const doc = {
          name: policy.name,
          nameCN: policy.nameCN,
          authorities: policy.authorities,
          status: policy.status,
        }
        await updateAccessPolicy(policy._id, doc)
        showSuccess('更新成功')
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const add = async function () {
      try {
        loading.value = true
        await addAccessPolicy({
          name: name.value,
          nameCN: nameCN.value,
          authorities: authorities.value,
        })
        await get()
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(get)

    return {
      loading,
      policies,
      update,
      add,
      authority,
      name,
      nameCN,
      authorities,
      permissionsMap,
    }
  }
}
</script>

<style scoped>
.el-tag {
  margin-left: 1em;
}
</style>