import { adminapi } from './base'

export const getTotalInfo = async function () {
  try {
    const res = await adminapi.get('/statistic/info')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getChartInfo = async function (startTime, endTime) {
  try {
    const res = await adminapi.get('/statistic/chartInfo', {
      params: {
        startTime,
        endTime,
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getNetworkInfo = async function (startTime, endTime, flowOutFilter) {
  try {
    const res = await adminapi.get('/statistic/netFlowOverview', {
      params: {
        startTime,
        endTime,
        flowOutFilter: JSON.stringify(flowOutFilter),
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}