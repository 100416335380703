import { adminapi } from './base'

export const createRedeems = async function ({ description, quantity, value, type }) {
  try {
    const res = await adminapi.put('/redeems/batchCreate', {
      description,
      quantity,
      value,
      type,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getRedeems = async function (statuses, page = 1, types = ['balance_redeem', 'balance_redeem_once']) {
  try {
    const res = await adminapi.get('/redeems/all', {
      params: {
        page,
        statuses: JSON.stringify(statuses),
        types: JSON.stringify(types),
      }
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateRedeem = async function (redeemId, updateDoc) {
  try {
    const res = await adminapi.post('/redeems/update', {
      redeemId,
      updateDoc,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const batchUpdateValue = async function (oldValue, newValue) {
  try {
    const res = await adminapi.post('/redeems/updateValue', {
      oldValue,
      newValue,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}