<template>
  <el-tabs v-model="tab" type="border-card">
    <el-tab-pane name="servers" label="Servers">
      <HealthServer v-if="tab === 'servers'" />
    </el-tab-pane>

    <el-tab-pane name="machines" label="Machines">
        <HealthMachine />
    </el-tab-pane>

    <el-tab-pane name="drive" label="Drive">
      <HealthDrive v-if="tab === 'drive'" />
    </el-tab-pane>

    <el-tab-pane name="storage" label="Storage">
      <HealthStorage v-if="tab === 'storage'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { ref } from 'vue'
import HealthMachine from './HealthMachine.vue'
import HealthDrive from './HealthDrive.vue'
import HealthStorage from './HealthStorage.vue'
import HealthServer from './HealthServer.vue'


export default {
  setup () {
    const tab = ref('servers')
    return {
      tab,
    }
  },
  components: {
    HealthMachine,
    HealthServer,
    HealthDrive,
    HealthStorage,
  }
}
</script>

<style scoped>

</style>