import { adminapi } from './base'

export const getSoftwares = async function () {
  try {
    const res = await adminapi.get(`/softwares/all`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateSoftware = async function (softwareId, doc) {
  try {
    const copiedDoc = JSON.parse(JSON.stringify(doc))
    delete copiedDoc._id
    delete copiedDoc.__v
    delete copiedDoc.updatedAt
    delete copiedDoc.createdAt

    const res = await adminapi.post('/softwares/update', {
      updateDoc: copiedDoc,
      softwareId,
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createSoftware = async function (software) {
  try {
    const res = await adminapi.put('/softwares/create', software)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}