import { adminapi } from './base'

export const createHint = async function ({ title, regexps, references, description }) {
  try {
    const res = await adminapi.put('/hints/create', {
      title,
      regexps,
      references,
      description,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getHints = async function (page = 1) {
  try {
    const params = {
      page
    }
    const res = await adminapi.get('/hints/all', {
      params,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateHint = async function (hintId, updateDoc) {
  try {
    const res = await adminapi.post('/hints/update', {
      hintId,
      updateDoc,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}