<template>
  <div id="nav" v-if="authenticated">
    <router-link to="/index">Home</router-link>
    <span> | </span>
    <router-link to="/pods">Pods</router-link>
    <span> | </span>
    <router-link to="/devops">Devops</router-link>
    <span> | </span>
    <router-link to="/extensions">Extensions</router-link>
    <span> | </span>
    <router-link to="/users">Users</router-link>
    <span> | </span>
    <router-link to="/modpacks">Modpacks</router-link>
    <span> | </span>
    <router-link to="/redeems">Redeems</router-link>
    <span> | </span>
    <router-link to="/withdraws">Withdraws</router-link>
    <span> | </span>
    <router-link to="/params">Params</router-link>
  </div>

  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import localforage from 'localforage'
import { mapGetters, useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()

    const checkAuth = async function () {
      const token = await localforage.getItem('pixelcloud-admin-token')
      if (token) {
        store.commit('update_authenticated', true)
        store.commit('update_token', token)
      }
    }

    return {
      checkAuth
    }
  },
  computed: {
    ...mapGetters([
      'authenticated'
    ])
  },
  mounted () {
    this.checkAuth()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  text-align: center;
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.el-autocomplete-suggestion {
  width: 330px !important;
}
</style>
