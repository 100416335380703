<template>
  <el-form>
    <el-form-item label="id" v-if="editingModpack._id">
      {{ editingModpack._id }}
    </el-form-item>
    <el-form-item label="名称">
      <el-input v-model="editingModpack.name"></el-input>
    </el-form-item>
    <el-form-item label="版本">
      <el-input v-model="editingModpack.version"></el-input>
    </el-form-item>
    <el-form-item label="描述">
      <el-input
        v-model="editingModpack.description"
        type="textarea"
        autosize></el-input>
    </el-form-item>
    <el-form-item label="能开的 PlanId">
      <el-tag
        v-for="(planId, $index) in editingModpack.requestPlanIds"
        :key="planId"
        closable
        @close="handleClose(editingModpack.requestPlanIds, $index)">{{ planId }}</el-tag>

      <el-select v-model="planIdToAdd">
        <el-option
          v-for="plan in plans"
          :key="plan._id"
          :value="plan._id"
          :label="plan.type"></el-option>
      </el-select>
      <el-button size="small" @click="editingModpack.requestPlanIds.push(planIdToAdd)">添加</el-button>
    </el-form-item>
    <el-form-item label="服务器核心">
      <el-select v-model="editingModpack.softwareId">
        <el-option
          v-for="software in softwares"
          :key="software._id"
          :label="`${software.type}-${software.version}`"
          :value="software._id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="launcherUrl">
      <el-input type="url" v-model="editingModpack.launcherUrl"></el-input>
    </el-form-item>
    <el-form-item label="依赖">
      <el-tag
        closable
        v-for="(dependency, $index) in editingModpack.dependencies"
        @close="handleClose(editingModpack.dependencies, $index)"
        :key="dependency._id">
        {{ dependency.name }}
        ({{ dependency.version }}, {{ dependency.supportV }})
      </el-tag>
      
      <el-autocomplete
        v-model="depQuery"
        @select="handleDepClick"
        :fetch-suggestions="querySearch"
        placeholder="name"></el-autocomplete>
    </el-form-item>
    <el-form-item label="status">
      <el-select v-model="editingModpack.status">
        <el-option
          label="available"
          value="available"></el-option>
        <el-option
          label="deleted"
          value="deleted"></el-option>
        <el-option
          label="pending"
          value="pending"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        @click="submit"
        :loading="loading">{{ newOne ? '新建' : '更新' }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import {
  updateModpack, createModpack, getExtensions,
  getPlans } from '@/api'
import { showSuccess, showError } from '@/utils'

export default {
  props: {
    modpack: {
      type: Object,
      default () {
        return {}
      }
    },
    softwares: {
      type: Array,
      default () {
        return []
      }
    }
  },
  setup (props) {
    const editingModpack = ref({})
    const loading = ref(false)
    const depQuery = ref('')
    const planIdToAdd = ref('')
    const plans = ref([])
    const newModpack = {
      name: '',
      version: '',
      description: '',
      softwareId: '',
      dependencies: [],
      status: 'pending',
      requestPlanIds: [],
      launcherUrl: '',
    }

    const newOne = computed(() => {
      if (props.modpack.name) {
        return false
      }

      return true
    })

    watch(props, () => {
      init()
    })

    const handleClose = function (arr, index) {
      arr.splice(index, 1)
    }

    const handleDepClick = function (item) {
      if (item._id) {
        if (editingModpack.value.dependencies) {
          editingModpack.value.dependencies.push(item)
        } else {
          editingModpack.value.dependencies = [item]
        }
        depQuery.value = ''
      }
    }

    const querySearch = async function (query, callback) {
      try {
        const extensions = await getExtensions('', 1, { name: query })
        const items = extensions.filter(extension => extension.status !== 'deleted').map(extension => {
          return {
            value: `${extension.name}(${extension.version},${extension.supportV})`,
            ...extension,
          }
        })
        callback(items)
      } catch (err) {
        console.error(err)
        showError(err)
      }
    }

    const submit = async function () {
      loading.value = true
      try {
        if (newOne.value) {
          await createModpack(editingModpack.value)
        } else {
          await updateModpack(editingModpack.value._id, {
            name: editingModpack.value.name,
            version: editingModpack.value.version,
            description: editingModpack.value.description,
            softwareId: editingModpack.value.softwareId,
            dependencies: editingModpack.value.dependencies,
            status: editingModpack.value.status,
            requestPlanIds: editingModpack.value.requestPlanIds,
            launcherUrl: editingModpack.value.launcherUrl,
          })
        }
        showSuccess('提交成功')
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const init = function () {
      if (newOne.value) {
        editingModpack.value = newModpack
      } else {
        editingModpack.value = props.modpack
        if (!editingModpack.value.requestPlanIds) {
          editingModpack.value.requestPlanIds = []
        }
      }
    }

    init()

    onMounted(async () => {
      plans.value = await getPlans()
    })

    return {
      editingModpack,
      newOne,
      handleClose,
      submit,
      handleDepClick,
      querySearch,
      depQuery,
      loading,
      planIdToAdd,
      plans,
    }
  },
}
</script>

<style scoped>
.el-tag {
  margin-left: 10px;
}
</style>