import { ElMessage } from 'element-plus'
import sha1 from 'js-sha1'

const showErrorMessage = function (content) {
  ElMessage({
    showClose: true,
    message: content,
    type: 'error'
  })
}

export const showError = function (err) {
  if (err.response && err.response.data && err.response.data.message) {
    showErrorMessage(err.response.data.message)
  } else if (err.message) {
    showErrorMessage(err.message)
  } else {
    showErrorMessage(err)
  }
}

export const showSuccess = function (message) {
  ElMessage({
    type: 'success',
    showClose: true,
    message
  })
}

export const getFileSha1 = async function (file, callback) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const chunksize = 1024 * 1024 * 2
    let start = 0
    const total = file.size
    let current = sha1
  
    const calculate = function () {
      if (start < total) {
        if (callback) {
          callback((start / total * 100).toFixed(2))
        }
  
        const end = Math.min(start + chunksize, total)
        reader.readAsArrayBuffer(file.slice(start, end))
        start = end
      } else {
        resolve(current.hex())
      }
    }
  
    reader.onload = function (event) {
      try {
        current = current.update(event.target.result)
        calculate()
      } catch (err) {
        reject(err)
      }
    }
  
    calculate()
  })
}

export const openExternal = function (url) {
  window.open(url, '_blank')
}

export const alternativeVersions = [
  '1.8.8',
  '1.12.2',
  '1.16.5',
  '1.17.1',
  '1.18',
  '1.7.10',
  '1.19',
]

export const extensionTypes = [
  { name: 'Forge Mod', value: 'mod_forge', dir: 'mods' },
  { name: 'Fabric Mod', value: 'mod_fabric', dir: 'mods' },
  { name: '行为包', value: 'behavior_pack', dir: 'behaviorpacks' },
  { name: 'Sponge 插件', value: 'plugin_sponge', dir: 'plugins/sponge' },
  { name: 'Spigot 插件', value: 'plugin_spigot', dir: 'plugins/spigot' },
  { name: 'Bungeecord 插件', value: 'plugin_bungeecord', dir: 'plugins/bungeecord' },
  { name: 'Nukkit 插件', value: 'plugin_nukkit', dir: 'plugins/nukkit' },
  { name: 'Java 版地图', value: 'map', dir: 'outmaps' },
  { name: '基岩版地图', value: 'map_pe', dir: 'outmaps' },
  { name: 'Java 版资源包', value: 'resource_pack', dir: 'resourcepacks' },
  { name: 'Nukkit 资源包', value: 'resource_pack_pe', dir: 'resourcepacks' },
  { name: '基岩版资源包', value: 'resource_pack_bds', dir: 'resourcepacks' },
  { name: '光影包', value: 'shader_pack', dir: 'shaderpacks' },
  { name: '数据包', value: 'data_pack', dir: 'datapacks' },
  { name: '基岩版数据包', value: 'data_pack_pe', dir: 'datapacks' },
  { name: '整合包配置', value: 'config_pack', dir: 'configpacks' },
  { name: 'Liteloader 插件', value: 'plugin_liteloader', dir: 'plugins/liteloader' },
]

export const planMap = new Map([
  ['619652e390cf89c2a56e7c15', 'Tiny'],
  ['619652e390cf89c2a56e7c16', 'Tiny e5'],
  ['619652e390cf89c2a56e7c19', 'Basic e5'],
  ['619652e390cf89c2a56e7c17', 'Standard'],
  ['619652e390cf89c2a56e7c18', 'Tiny i9'],
  ['65c1183a7ba6ee001ca16ee4', 'Tiny 7950'],
  ['61d6ff24b45f4a001c42eeaa', 'Basic i9'],
  ['65c118c47ba6ee001ca16eea', 'Basic 7950'],
  ['61d7062e8a8f96001cd473cf', 'Standard i9'],
  ['65c119297ba6ee001ca16ef0', 'Standard 7950'],
  ['65b4aabf99bfb7001fe32d54', 'Basic Palworld'],
  ['65b4ab2399bfb7001fe32d57', 'Standard Palworld'],
])

export const bedrockSoftwareIds = ['61aca24a04859d001cd201ac', '61c45ed7503c62001e94e4cc']
export const bungeecordSoftwareIds = ['619652e390cf89c2a56e7bf9']

export const getChartOptionsFactory = function (startTime, intervalTag) {
  return function (datapoints, key, formatter = function () { return this.y }) {
    const data = datapoints.map(datapoint => {
      return [
        new Date(datapoint.createdAt).getTime(),
        datapoint[key],
      ]
    })
  
    return {
      chart: {
        height: 200,
      },
      rangeSelector: {
        enabled: false
      },
      title: {
        text: null
      },
      time:{
        useUTC: false
      },
      xAxis: {
        title: {
          text: null
        },
        labels: {
          enabled: true
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%m月%e日',
          week: '%e. %b',
          month: '%b \'%y',
          year: '%Y'
        }
      },
      yAxis: {
        min: 0,
        max: [
          'cpuUsage',
          'memoryUsage',
          'netUsage',
          'diskUsage',
          'cpuPercent',
          'memoryPercent',
        ].includes(key) ? 100 : undefined,
        title: {
          text: null
        },
        labels: {
          enabled: true,
          algin: 'left'
        }
      },
      series: [{
        data,
        showInLegend: false,
        pointStart: startTime,
        pointInterval: intervalTag * 60 * 1000
      }],
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      scrollbar: {
        enabled: true,
        height: 0
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M',
        pointFormatter: formatter
      },
      credits: {
        enabled: false
      }
    }
  }
}

export const parseToServers = function (rawDatas) {
  const servers = []
  rawDatas.forEach(data => {
    data.ecss.forEach(ecs => {
      let index = -1
      const added = servers.some((server, i) => {
        if (server.instanceId === ecs.instanceId) {
          index = i
          return true
        }

        return false
      })

      if (!added) {
        const server = {
          instanceId: ecs.instanceId,
          hostname: ecs.hostname,
          datapoints: []
        }
        servers.push(server)
      } else {
        servers[index].datapoints.push({
          createdAt: data.createdAt,
          ...ecs,
        })
      }
    })
  })

  return servers
}

export const parseToDitingServers = function (rawDatas) {
  const servers = []
  rawDatas.forEach(data => {
    data.ditings.forEach(diting => {
      let index = -1
      const added = servers.some((server, i) => {
        if (server.instanceId === diting.ditingId) {
          index = i
          return true
        }

        return false
      })

      if (!added) {
        const server = {
          instanceId: diting.ditingId,
          hostname: diting.hostname,
          datapoints: [],
          intranetIp: diting.intranetIp ? diting.intranetIp : ''
        }
        servers.push(server)
      } else {
        let netInRate = 0
        let netOutRate = 0
        let diskUsage = 0
        let ioReadRate = 0
        let ioWriteRate = 0

        if (diting.net[0]) {
          netInRate = diting.net[0].receiveRate
          netOutRate = diting.net[0].sendRate
        }

        if (diting.disk[0]) {
          diskUsage = diting.disk[0].usedSize / diting.disk[0].totalSize * 100
        }

        if (diting.io[0]) {
          ioWriteRate = diting.io[0].writeRate
          ioReadRate = diting.io[0].readRate
        }

        servers[index].datapoints.push({
          createdAt: data.createdAt,
          cpuUsage: diting.cpu.used / diting.cpu.total * 100,
          memoryUsage: diting.memory.used / diting.memory.total * 100,
          netInRate,
          netOutRate,
          diskUsage,
          systemLoad: diting.systemLoad,
          ioReadRate,
          ioWriteRate,
        })

        if (diting.intranetIp) {
          servers[index].intranetIp = diting.intranetIp
        }
      }
    })
  })

  return servers
}

export const callNameMap = new Map([
  ['open_mc_cli', '获取控制台内容'],
  ['cli_ping', '与控制台通信'],
  ['start_server', '开启服务器'],
  ['stop_server', '关闭服务器'],
  ['get_mc_properties', '获取 server.properties 属性'],
  ['set_mc_properties', '设置 server.properties 属性'],
  ['add_extensions_v3', '安装拓展'],
  ['remove_extensions_v2', '卸载拓展'],
  ['async_backup_world_v2', '备份地图'],
  ['ls_folder_filtered', '列目录'],
  ['read_file', '读取文件'],
  ['write_file', '写入文件'],
  ['create_folder', '创建文件'],
  ['async_download_file', '下载文件'],
  ['copy_file', '复制文件'],
  ['move_file', '移动文件'],
  ['remove_file', '删除文件'],
  ['yaml_get', '获取 yaml 属性'],
  ['yaml_set', '设置 yaml 属性'],
  ['get_white_list', '获取白名单'],
  ['removetree_mcfolder', '删除文件夹'],
  ['set_pod_status_listener', '初始化服务器状态'],
  ['async_download_world_v2', '更换世界地图'],
  ['get_all_tasks', '获取耗时任务列表'],
  ['create_agent_async_task', '创建耗时任务'],
  ['peek_task', '查询耗时任务状态'],
  ['async_update_mc_core', '更新核心'],
  ['generate_client_dep_bundle', '打包客户端 zip'],
  ['restart_server', '重启服务器'],
  ['async_unzip_file', '解压文件'],
  ['parse_bds_pack', '解析 mcpack'],
  ['parse_bds_addon', '解析 mcaddon'],
  ['parse_jar', '解析 jar'],
  ['parse_mc_core', '解析核心压缩包'],
  ['zip_folder', '压缩文件'],
  ['get_file_download_url', '获取文件下载地址'],
  ['set_server_java_env', '更换 Java 环境'],
  ['reset_mc_server_v2', '重装 MC 服务器'],
  ['get_start_args', '获取启动参数'],
  ['set_additional_args', '设置启动参数'],
  ['get_server_start_clistr', '获取静态启动参数'],
  ['parse_serverpack_to_modpack', '解析整合包'],
  ['parse_mc_core_and_modpack', '解析整合包'],
  ['serverpack_to_modpack', '解析整合包'],
  ['read_shared_file', '获取崩溃日志分享'],
  ['config_yaml_get', '管理 Bungeecord'],
  ['get_mc_core_md5', '检查核心更新'],
  ['set_pod_status_listener', '显示 CPU 内存'],
  ['cli_write', '执行命令'],
])