import { adminapi } from './base'

export const getExtensions = async function (type, page = 1, { name, extensionId } = {
  name: null,
  extensionId: '',
}) {
  try {
    let url = `/extensions/all?page=${page}`
    if (name) {
      url = `${url}&name=${name}`
    }
    if (extensionId) {
      url = `${url}&extensionId=${extensionId}`
    }
    if (type) {
      url = `${url}&type=${type}`
    }

    const res = await adminapi.get(url)

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateExtension = async function (extensionId, doc) {
  try {
    const copiedDoc = JSON.parse(JSON.stringify(doc))
    delete copiedDoc._id
    const res = await adminapi.post('/extensions/update', {
      updateDoc: copiedDoc,
      extensionId
    })

    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const createExtension = async function (doc) {
  try {
    const res = await adminapi.put('/extensions/create', doc)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const parseJar = async function (url) {
  try {
    const res = await adminapi.put('/tasks/jarparse', {
      downloadUrl: url,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}