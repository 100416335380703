<template>
  <el-row type="flex" justify="center">
    <el-col :span="18">
      <el-table :data="withdraws">
        <el-table-column label="userId" prop="userId"></el-table-column>
        <el-table-column label="账号" prop="alipayAccount"></el-table-column>
        <el-table-column label="姓名" prop="realName"></el-table-column>
        <el-table-column label="createdAt">
          <template #default="scope">
            {{ new Date(scope.row.createdAt).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template #default="scope">
            {{ scope.row.amount / 100 }}
          </template>
        </el-table-column>
        <el-table-column label="原因" prop="reason"></el-table-column>
        <el-table-column label="操作" min-width="100">
          <template #default="scope">
            <el-button-group>
              <el-button
                @click="setStatus(scope.row, 'completed')"
                :loading="scope.row.loading">已打钱</el-button>
              <el-button
                type="danger"
                @click="setStatus(scope.row, 'rejected')"
                :loading="scope.row.loading">拒绝打钱</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getWithdraws, setWithdrawStatus } from '@/api'
import { showError, showSuccess } from '@/utils'

export default {
  setup() {
    const withdraws = ref([])

    const fetch = async function () {
      try {
        withdraws.value = await getWithdraws()
      } catch (err) {
        console.error(err)
        showError(err)
      }
    }

    const setStatus = async function (withdraw, status) {
      withdraw.loading = true
      try {
        await setWithdrawStatus(withdraw._id, status)
        showSuccess('成功')
        await fetch()
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        withdraw.loading = false
      }
    }

    onMounted(fetch)

    return {
      withdraws,
      setStatus,
    }
  },
}
</script>

<style scoped>

</style>