<template>
  <div>
    <el-button @click="showCreate">新建</el-button>
    
    <el-table :data="hints">
      <el-table-column label="title" prop="title"></el-table-column>
      <el-table-column label="references" prop="references"></el-table-column>
      <el-table-column label="regexps" prop="regexps"></el-table-column>
      <el-table-column label="description" prop="description"></el-table-column>
      <el-table-column label="operation">
        <template #default="scope">
          <el-button @click="showUpdate(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="showDialog">
      <el-form>
        <el-form-item v-if="editingHint._id">
          {{ editingHint._id }}
        </el-form-item>
        
        <el-form-item label="title">
          <el-input v-model="editingHint.title"></el-input>
        </el-form-item>

        <el-form-item label="description">
          <el-input v-model="editingHint.description" type="textarea"></el-input>
        </el-form-item>
      
        <el-form-item label="references">
          <el-tag
            closable
            v-for="(reference, index) in editingHint.references"
            :key="reference"
            @close="editingHint.references.splice(index, 1)">
            <el-link :href="reference">{{ reference }}</el-link>
          </el-tag>
        </el-form-item>

        <el-form-item>
          <el-input v-model="reference" placeholder="url"></el-input>
          <el-button
            @click="editingHint.references.push(reference)">添加 reference</el-button>
        </el-form-item>
      
        <el-form-item label="regexps">
          <el-tag
            closable
            v-for="(regexp, index) in editingHint.regexps"
            :key="regexp"
            @close="editingHint.regexps.splice(index, 1)">{{ regexp }}</el-tag>
        </el-form-item>

        <el-form-item>
          <el-input v-model="regexp" placeholder="regexp"></el-input>
          <el-button @click="editingHint.regexps.push(regexp)">添加 regexp</el-button>
        </el-form-item>
      
        <el-form-item>
          <el-button :loading="making" @click="editingHint._id ? update() : make()">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { useHint } from '@/composables'

export default {
  setup () {
    return {
      ...useHint()
    }
  },
}
</script>

<style scoped>

</style>