<template>
  <el-button @click="edit()">新建</el-button>
  <div>
    <el-table :data="softwares">
      <el-table-column label="_id" prop="_id"></el-table-column>
      <el-table-column label="type" prop="type"></el-table-column>
      <el-table-column label="version" prop="version"></el-table-column>
      <el-table-column label="image" prop="image"></el-table-column>
      <el-table-column label="status" prop="status"></el-table-column>
      <el-table-column label="" prop="">
        <template #default="scope">
          <el-button @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="showDialog">
      <el-form>
        <el-form-item label="_id" v-if="editingSoftware._id">
          {{ editingSoftware._id }}
        </el-form-item>
        <el-form-item label="type">
          <el-input v-model="editingSoftware.type"></el-input>
        </el-form-item>
        <el-form-item label="version">
          <el-input v-model="editingSoftware.version"></el-input>
        </el-form-item>
        <el-form-item label="subversion(optional)">
          <el-input v-model="editingSoftware.subversion"></el-input>
        </el-form-item>
        <el-form-item label="image">
          <el-input v-model="editingSoftware.image"></el-input>
        </el-form-item>
        <el-form-item label="coreHash">
          <el-input v-model="editingSoftware.coreHash"></el-input>
        </el-form-item>
        <el-form-item label="coreDownloadUrl">
          <el-input v-model="editingSoftware.coreDownloadUrl"></el-input>
        </el-form-item>
        <el-form-item label="status">
          <el-select v-model="editingSoftware.status">
            <el-option label="available" value="available"></el-option>
            <el-option label="testing" value="testing"></el-option>
            <el-option label="unavailable" value="unavailable"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="isBedrock">
          <el-switch v-model="editingSoftware.isBedrock"></el-switch>
        </el-form-item>
        <el-form-item label="javaEnv">
          <el-select v-model="editingSoftware.javaEnv">
            <el-option
              v-for="env in javaEnvs"
              :key="env"
              :value="env"
              :label="env"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="entryFile">
          <el-input v-model="editingSoftware.entryFile"></el-input>
        </el-form-item>
        <el-form-item label="flags">
          <el-tag
            v-for="(item, $index) in editingSoftware.flags"
            :key="item"
            closable
            @close="handleClose(editingSoftware.flags, $index)">{{ item }}</el-tag>

          <el-select v-model="flag">
            <el-option
              v-for="item in flags"
              :key="item"
              :value="item"
              :label="item"></el-option>
          </el-select>
          <el-button @click="editingSoftware.flags.push(flag)">添加</el-button>
        </el-form-item>
        <el-form-item label="startArgs">
          <el-tag
            v-for="(item, $index) in editingSoftware.startArgs"
            :key="item"
            closable
            @close="handleClose(editingSoftware.startArgs, $index)">{{ item }}</el-tag>

          <el-input v-model="startArg"></el-input>
          <el-button @click="editingSoftware.startArgs.push(startArg)">添加</el-button>
        </el-form-item>
        <el-form-item label="coreFiles">
          <el-tag
            v-for="(item, $index) in editingSoftware.coreFiles"
            :key="item"
            closable
            @close="handleClose(editingSoftware.coreFiles, $index)">{{ item }}</el-tag>

          <el-input v-model="coreFile"></el-input>
          <el-button @click="editingSoftware.coreFiles.push(coreFile)">添加</el-button>
        </el-form-item>
        <el-form-item label="secondaryFiles">
          <el-tag
            v-for="(item, $index) in editingSoftware.secondaryFiles"
            :key="item"
            closable
            @close="handleClose(editingSoftware.secondaryFiles, $index)">{{ item }}</el-tag>

          <el-input v-model="secondaryFile"></el-input>
          <el-button @click="editingSoftware.secondaryFiles.push(secondaryFile)">添加</el-button>
        </el-form-item>
        <el-form-item label="executableFiles">
          <el-tag
            v-for="(item, $index) in editingSoftware.executableFiles"
            :key="item"
            closable
            @close="handleClose(editingSoftware.executableFiles, $index)">{{ item }}</el-tag>

          <el-input v-model="executableFile"></el-input>
          <el-button @click="editingSoftware.executableFiles.push(executableFile)">添加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" @click="editingSoftware._id ? update() : create()">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { useSoftware } from '@/composables'

export default {
  setup () {
    return {
      ...useSoftware()
    }
  },
}
</script>

<style scoped>
.el-tag {
  margin-right: 1em;
  margin-top: 1em;
}

.el-button {
  margin-top: 1em;
}
</style>