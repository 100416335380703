import { onMounted, ref, watch } from 'vue'
import { getUsers, updateUser, updateBalance, getUserRefund } from '@/api'
import { Search } from '@element-plus/icons'
import { showError, showSuccess } from '@/utils'

export const useUser = function () {
  const users = ref([])
  const keyword = ref('')
  const searchType = ref('nickname')
  const page = ref(1)
  const editingUser = ref({})
  const dialogVisible = ref(false)
  const updatingUser = ref(false)
  const updatingBalance = ref(false)
  const balance = ref(0)
  const editingUserRefund = ref(0)
  const withdrawable = ref(false)

  watch(dialogVisible, async () => {
    if (!dialogVisible.value) {
      editingUser.value = {}
      editingUserRefund.value = 0
    } else {
      const { refundAmount } = await getUserRefund(editingUser.value._id)
      editingUserRefund.value = refundAmount / 100
    }
  })

  const fetchUsers = async function () {
    try {
      const query = { page: page.value }
      query[searchType.value] = keyword.value
  
      users.value = await getUsers(query)
    } catch (err) {
      showError(err)
    }
  }

  const showDialog = function (user) {
    editingUser.value = Object.assign({}, user)
    dialogVisible.value = true
    balance.value = 0
  }

  const update = async function () {
    updatingUser.value = true

    try {
      const doc = Object.assign({}, editingUser.value)
      delete doc._id

      await updateUser(editingUser.value._id, {
        nickname: doc.nickname,
        gender: doc.gender,
        email: doc.email,
        emailVerified: doc.emailVerified,
        balance: doc.balance,
        income: doc.income,
      })
      showSuccess('更新成功')
    } catch (err) {
      showError(err)
    } finally {
      updatingUser.value = false
    }
  }

  const updateAmount = async function (amount) {
    updatingBalance.value = true

    try {
      await updateBalance(editingUser.value._id, amount, withdrawable.value)
      editingUser.value.balance += amount
      showSuccess('更新成功')
    } catch (err) {
      showError(err)
    } finally {
      updatingBalance.value = false
    }
  }

  onMounted(fetchUsers)

  return {
    users,
    keyword,
    searchType,
    Search,
    fetchUsers,
    page,
    showDialog,
    dialogVisible,
    updatingUser,
    updatingBalance,
    update,
    updateAmount,
    balance,
    editingUser,
    editingUserRefund,
    withdrawable,
  }
}