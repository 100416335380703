import { adminapi } from './base'

export const addAccessPolicy = async function ({ name, nameCN, authorities }) {
  try {
    await adminapi.put('/authGroups/create', {
      name,
      nameCN,
      authorities
    })
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getAccessPolicies = async function () {
  try {
    const res = await adminapi.get('/authGroups/all')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateAccessPolicy = async function (authGroupId, updateDoc) {
  try {
    const res = await adminapi.post('/authGroups/update', {
      authGroupId,
      updateDoc,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}