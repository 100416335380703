import { adminapi } from './base'
import { Base64 } from 'js-base64'
import { planMap } from '@/utils'
import { getSoftwares } from './software'

const { decode } = Base64

const attatchPlan = function (servers) {
  servers.forEach(server => {
    if (server.planId) {
      server.planName = planMap.get(server.planId)
    }
  })
}

const attachSoftware = function (servers, softwares) {
  servers.forEach(server => {
    if (server.softwareId) {
      for (const software of softwares.values()) {
        if (software._id === server.softwareId) {
          server.software = software
          return
        }
      }
    }
  })
}

export const getExpiredPods = async function (page = 1) {
  try {
    const res = await adminapi.get(`/pods/allInactive?page=${page}`)
    const pods = res.data.result
    attatchPlan(pods)
    return pods
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getAllPods = async function (page = 1) {
  try {
    const [
      softwares,
      res
    ] = await Promise.all([
      getSoftwares(),
      adminapi.get(`/pods/all?page=${page}`)
    ])

    const pods = res.data.result
    attatchPlan(pods)
    attachSoftware(pods, softwares)
    return pods
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const startServer = async function (podId) {
  try {
    const res = await adminapi.post('/pods/startServer', { podId })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const stopServer = async function (podId) {
  try {
    const res = await adminapi.post('/pods/stopServer', { podId })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const deallocPods = async function (podIds) {
  try {
    const res = await adminapi.delete('/pods/batch', { data: { podIds } })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const restartPod = async function (podId) {
  try {
    const res = await adminapi.post('/pods/restartPod', {
      podId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const deletePod = async function (podId) {
  try {
    const res = await adminapi.delete(`/pods/${podId}/deployment`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const restartSwarm = async function () {
  try {
    const res = await adminapi.post('/pods/restartAllPods')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const listDir = async function (podId, dirpath) {
  try {
    const res = await adminapi.get(`/pods/lsdir?podId=${podId}&dirpath=${dirpath}`)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getFileContent = async function (podId, filepath) {
  try {
    const res = await adminapi.get(`/pods/fileContent?podId=${podId}&filepath=${filepath}`)
    return decode(res.data.result)
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const addExtensions = async function (serverId, extensionIds) {
  try {
    const data = {
      podId: serverId,
      extIds: extensionIds,
    }

    const res = await adminapi.post('/pods/addExtensions', data)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const removeExtensions = async function (serverId, extensionIds) {
  try {
    const data = {
      podId: serverId,
      extIds: extensionIds
    }

    const res = await adminapi.post('/pods/removeExtensions', data)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const updateInstalledExtensions = async function (extensionId) {
  try {
    const res = await adminapi.post('/pods/updateInstalledExt', {
      extId: extensionId
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const searchPods = async function ({ podId, subdomain, name, userId }) {
  try {
    const params = {
      podId,
      subdomain,
      name,
      userId,
    }

    const [
      softwares,
      res
    ] = await Promise.all([
      getSoftwares(),
      adminapi.get('/pods/search', {
        params
      })
    ])

    const pods = res.data.result
    attatchPlan(pods)
    attachSoftware(pods, softwares)

    return pods
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const broadcastInGame = async function (content, podIds = []) {
  try {
    const data = {
      content
    }

    if (podIds.length > 0) {
      data.podIds = podIds
    } else {
      data.toAll = true
    }

    const res = await adminapi.post('/pods/broadcastMsg', data)
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}