import { getAllTasks, removeTask } from '@/api'
import { onMounted, onUnmounted } from 'vue'
import { showError } from '@/utils'

const callbacks = new Map()
let intervalId = null

export const useTask = function () {
  const addTask = function (task, callback) {
    if (!task.id) {
      throw new Error('no task id')
    }

    if (callback) {
      callbacks.set(task.id, callback)      
    }
  }

  const queryTasks = async function () {
    if (callbacks.size === 0) {
      return
    }

    const tasks = await getAllTasks()
    for (const task of tasks.values()) {
      if (['created', 'running'].includes(task.status)) {
        continue
      }

      if (task.status === 'completed') {
        if (callbacks.has(task.id)) {
          const result = JSON.parse(task.result)
          callbacks.get(task.id)(null, result[1])
          callbacks.delete(task.id)
        }

        await removeTask(task.id)
      }

      if (task.status === 'error') {
        if (callbacks.has(task.id)) {
          callbacks.get(task.id)(new Error(`failed to ${task.method}`))
          callbacks.delete(task.id) 
        } else {
          showError(`failed to ${task.method}`)
        }

        await removeTask(task.id)
      }
    }
  }

  onMounted(() => {
    intervalId = setInterval(() => {
      try {
        queryTasks()
      } catch (err) {
        showError(err)        
      }
    }, 2000)
  })

  onUnmounted(() => {
    clearInterval(intervalId)
  })

  return {
    addTask
  }
}