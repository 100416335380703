<template>
  <el-tabs v-model="activeTab">
    <el-tab-pane
      v-for="(datas, category) in categories"
      :key="category"
      :label="category"
      :name="category">
      <el-table :data="datas">
        <el-table-column label="_id" prop="_id"></el-table-column>
        <el-table-column label="createdAt">
          <template #default="scope">
            {{ new Date(scope.row.createdAt).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column label="content">
          <template #default="scope">
            <pre @click="show(scope.row)">{{ scope.row.errorBody }}</pre>
          </template>
        </el-table-column>
        <el-table-column label="status" prop="status"></el-table-column>
        <el-table-column label="count" prop="count"></el-table-column>
        <el-table-column label="operation">
          <template #default="scope">
            <el-button
              @click="settle(scope.row._id)"
              :loading="loading">update</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>

  <el-dialog v-model="showDialog">
    <pre>
      {{ dialogContent }}
    </pre>
  </el-dialog>
</template>

<script>
import { getAllErrors, settleError } from '@/api'
import { computed, onMounted, ref } from 'vue'
import { showError } from '@/utils'

export default {
  setup () {
    const loading = ref(false)
    const rawDatas = ref([])
    const activeTab = ref('')
    const showDialog = ref(false)
    const dialogContent = ref('')

    const categories = computed(() => {
      const sources = {}

      for (const rawData of rawDatas.value.values()) {
        if (sources[rawData.source]) {
          sources[rawData.source].push(rawData)
        } else {
          sources[rawData.source] = [rawData]
        }
      }

      return sources
    })

    const get = async function () {
      try {
        rawDatas.value = await getAllErrors()
        if (activeTab.value === '') {
          activeTab.value = Object.keys(categories.value)[0]          
        }
        console.log(categories.value)
      } catch (err) {
        showError(err)
      }
    }

    const settle = async function (errId) {
      loading.value = true
      try {
        await settleError(errId)
        await get()
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const show = function (log) {
      dialogContent.value = log.errorBody
      showDialog.value = true
    }

    onMounted(get)

    return {
      get,
      settle,
      loading,
      categories,
      activeTab,
      showDialog,
      dialogContent,
      show,
    }
  },
}
</script>

<style scoped>
pre:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>