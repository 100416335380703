<template>
  <el-button-group style="margin: 1em;">
    <el-button
      type="danger"
      :loading="restarting"
      @click="restartK8S">Restart Swarm</el-button>
    <el-button
      :loading="pinging"
      @click="attachPings(pods)">Ping All</el-button>
    <el-button
      :loading="getting"
      @click="get">Refresh Pods</el-button>
  </el-button-group>

  <el-input v-model="searchWords" @keyup.enter="search">
    <template #prepend>
      <el-select v-model="searchType" placeholder="类型">
        <el-option label="_id" value="podId"></el-option>
      </el-select>
    </template>

    <template #append>
      <el-button :loading="getting" :icon="SearchIcon" @click="search"></el-button>
    </template>
  </el-input>

  <el-table :data="pods">
    <el-table-column prop="_id" label="id"></el-table-column>
    <el-table-column label="name">
      <template #default="scope">
        <el-button type="text" @click="showPod(scope.row._id)">{{ scope.row.name }}</el-button>
      </template>
    </el-table-column>
    <el-table-column prop="status" label="status"></el-table-column>
    <el-table-column prop="node" label="node"></el-table-column>
    <el-table-column label="ping" prop="ping"></el-table-column>
    <el-table-column label="operations">
      <template #default="scope">
        <el-button
          :loading="scope.row.pinging"
          @click="attachPing(scope.row)">ping</el-button>
        <el-button
          type="warning"
          :loading="scope.row.restarting || restarting"
          @click="restart(scope.row)">Restart Pod</el-button>
        <el-button
          type="danger"
          :loading="scope.row.deleting"
          @click="deleteDeployment(scope.row)">Delete Deployment</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    v-model:currentPage="page"
    layout="prev, pager, next, jumper"
    :page-size="10"
    @prev-click="page--"
    @next-click="page++"
    :total="1000">
  </el-pagination>

  <el-dialog v-model="showDialog">
    <DevopPodHealth v-if="showDialog" :pod-id="showingPodId"></DevopPodHealth>
  </el-dialog>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { showError, showSuccess } from '@/utils'
import { getAllPods, restartPod, restartSwarm, searchPods, getMcPing, deletePod } from '@/api'
import { Search as SearchIcon } from '@element-plus/icons'
import  DevopPodHealth from './DevopPodHealth.vue'

export default {
  setup() {
    const pods = ref([])
    const page = ref(1)
    const restarting = ref(false)
    const getting = ref(false)
    const searchType = ref('podId')
    const searchWords = ref('')
    const pinging = ref(false)
    const showDialog = ref(false)
    const showingPodId = ref('')

    const attachPing = async function (pod) {
      try {
        pod.pinging = true
        if (pod.status !== 'running') {
          pod.ping = 'stopped'
        } else {
          pod.ping = await getMcPing(pod._id)
        }
      } catch (err) {
        showError(err)
      } finally {
        pod.pinging = false
      }
    }

    const attachPings = async function (pods) {
      pinging.value = true
      try {
        const promises = []
        for (const pod of pods.values()) {
          pod.pinging = true
          if (pod.status !== 'running') {
            promises.push(Promise.resolve('stopped'))
          } else {
            promises.push(getMcPing(pod._id))
          }
        }
        const pings = await Promise.all(promises)
        for (const [index, ping] of pings.entries()) {
          pods[index].ping = ping
          pods[index].pinging = false
        }
      } catch (err) {
        showError(err)
      } finally {
        pinging.value = false
      }
    }

    watch(page, () => {
      get()
    })

    const showPod = function (podId) {
      showingPodId.value = podId
      showDialog.value = true
    }

    const get = async function () {
      getting.value = true
      try {
        pods.value = await getAllPods(page.value)
      } catch (err) {
        showError(err)
      } finally {
        getting.value = false
      }
    }

    const restart = async function (pod) {
      try {
        pod.restarting = true
        await restartPod(pod._id)
        showSuccess('重启成功')
      } catch (err) {
        showError(err)
      } finally {
        pod.restarting = false
      }
    }

    const deleteDeployment = async function (pod) {
      try {
        pod.deleting = true
        await deletePod(pod._id)
        showSuccess('删除成功')
      } catch (err) {
        showError(err)
      } finally {
        pod.deleting = false
      }
    }

    const restartK8S = async function () {
      try {
        restarting.value = true
        await restartSwarm()
        showSuccess('重启成功')
      } catch (err) {
        showError(err)
      } finally {
        restarting.value = false
      }
    }

    const search = async function () {
      getting.value = true
      const query = {}
      query[searchType.value] = searchWords.value

      try {
        pods.value = await searchPods(query)
        await attachPings(pods.value)
      } catch (err) {
        showError(err)
      } finally {
        getting.value = false
      }
    }

    onMounted(get)

    return {
      pods,
      page,
      get,
      restart,
      restartK8S,
      restarting,
      getting,
      searchWords,
      searchType,
      SearchIcon,
      search,
      attachPings,
      attachPing,
      pinging,
      showDialog,
      showPod,
      showingPodId,
      deleteDeployment,
    }
  },
  components: {
    DevopPodHealth,
  }
}
</script>

<style scoped>
.el-select {
  width: 180px;
}
</style>