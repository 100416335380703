import { ref, onMounted } from 'vue'
import { showError, showSuccess } from '@/utils'
import { getPlans, updatePlan, createPlan } from '@/api'

const alternativeNodes = ['shanghai', 'shanghai-boost', 'shanghai-i9']

export const usePlan = function () {
  const plans = ref([])
  const loading = ref(false)
  const editingPlan = ref({})
  const showDialog = ref(false)

  const get = async function () {
    try {
      plans.value = await getPlans()
    } catch (err) {
      showError(err)
    }
  }

  const showCreate = function () {
    editingPlan.value = {
      type: '',
      costPerDay: 0,
      maxCpu: 0,
      maxMemory: 0,
      status: 'unavailable',
      availableNodes: [],
      hardware: ''
    }
    showDialog.value = true
  }

  const update = async function () {
    loading.value = true

    try {
      const doc = {
        type: editingPlan.value.type,
        costPerDay: editingPlan.value.costPerDay,
        minCpu: editingPlan.value.minCpu,
        maxCpu: editingPlan.value.maxCpu,
        minMemory: editingPlan.value.minMemory,
        maxMemory: editingPlan.value.maxMemory,
        status: editingPlan.value.status,
        promisedPlayers: editingPlan.value.promisedPlayers,
        availableNodes: editingPlan.value.availableNodes,
        freeDiskSize: editingPlan.value.freeDiskSize,
        freeNetFlow: editingPlan.value.freeNetFlow,
        bandwidth: editingPlan.value.bandwidth,
        cpuType: editingPlan.value.cpuType,
        hardware: editingPlan.value.hardware,
      }

      if (editingPlan.value._id) {
        await updatePlan(editingPlan.value._id, doc)
        showSuccess('编辑成功')
      } else {
        await createPlan(doc)
        showSuccess('创建成功')
      }
      showDialog.value = false
      await get()
    } catch (err) {
      showError(err)
    } finally {
      loading.value = false
    }
  }

  const edit = function (item) {
    editingPlan.value = Object.assign({}, item)
    showDialog.value = true
  }

  onMounted(get)

  return {
    plans,
    get,
    editingPlan,
    showDialog,
    edit,
    update,
    loading,
    alternativeNodes,
    showCreate,
  }
}