<template>
  <div class="home">
    <el-row type="flex" justify="center">
      <el-col :span="6" :xs="24">
        <h1>PixelCloud 冲鸭！</h1>
        <h1>在线玩家：{{ gettingInfo ? '获取中' : playersCount }}</h1>
        <h1>服务器数：{{ gettingInfo ? '获取中' : serversCount }}</h1>
        <h1>付费服务器数：{{ gettingInfo ? '获取中' : paidServersCount }}</h1>
        <h1>今日消费：￥{{ todayRevenue }}</h1>
        <h1>今日客单价：￥{{ totayATV.toFixed(2) }}</h1>
        <el-button @click="getInfo">刷新</el-button>
      </el-col>
      <el-col :span="14" :xs="24">
        <h2>服务器在线人数</h2>
        <el-button :loading="gettingInfo" @click="getInfo">刷新</el-button>
        <el-table :data="playerDetailData"  v-loading="gettingInfo">
          <el-table-column label="服务器名称">
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="在线人数" width="180">
            <template #default="scope">
              {{ scope.row.playerCount }} 人
            </template>
          </el-table-column>
          <el-table-column label="PodId">
            <template #default="scope">
              {{ scope.row._id }}
            </template>
          </el-table-column>
        </el-table>

        <h2>异常用户</h2>
        <el-table :data="cheaters" v-loading="gettingInfo">
          <el-table-column label="nickname" prop="nickname"></el-table-column>
          <el-table-column label="email" prop="email"></el-table-column>
          <el-table-column label="今日消费">
            <template #default="scope">
              {{ scope.row.cost / 100 }}
            </template>
          </el-table-column>
          <el-table-column label="余额">
            <template #default="scope">
              {{ scope.row.balance / 100 }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import { getTotalInfo } from '@/api'
import { onMounted, ref, computed } from 'vue'
import { showError } from '@/utils'

export default {
  name: 'Home',
  setup () {
    const store = useStore()
    const playersCount = ref(0)
    const serversCount = ref(0)
    const paidServersCount = ref(0)
    const incomes = ref([])
    const gettingInfo = ref(false)
    const authenticated = computed(() => store.getters.authenticated)
    const playerDetailData = ref([])

    const todayRevenue = computed(() => {
      let todayData = null
      const day = new Date().getDate()
      for (const data of incomes.value.values()) {
        if (day === data.dayOfMonth.dayOfMonth) {
          todayData = data
          break
        }
      }

      if (!todayData) {
        return 0
      }

      return todayData.totalIncome / 100
    })

    const cheaters = computed(() => {
      let todayData = null
      const day = new Date().getDate()
      for (const data of incomes.value.values()) {
        if (day === data.dayOfMonth.dayOfMonth) {
          todayData = data
          break
        }
      }

      if (!todayData) {
        return []
      }

      return todayData.specialUsers
    })

    const totayATV = computed(() => {
      let todayData = null
      const day = new Date().getDate()
      for (const data of incomes.value.values()) {
        if (day === data.dayOfMonth.dayOfMonth) {
          todayData = data
          break
        }
      }

      if (!todayData) {
        return 0
      }

      return todayData.costPerUser / 100
    })

    const getInfo = async function () {
      if (!authenticated.value) {
        return
      }

      gettingInfo.value = true

      try {
        const { playerCount, runningServersCount, freeServersCount, incomeData, playerDetail } = await getTotalInfo()
        playersCount.value = playerCount
        serversCount.value = runningServersCount
        paidServersCount.value = runningServersCount - freeServersCount
        playerDetailData.value = playerDetail
        incomes.value = incomeData
      } catch (err) {
        showError(err)
      } finally {
        gettingInfo.value = false
      }
    }

    onMounted(async () => {
      await getInfo()
    })

    return {
      getInfo,
      gettingInfo,
      playersCount,
      serversCount,
      paidServersCount,
      todayRevenue,
      playerDetailData,
      totayATV,
      cheaters,
    }
  },
  computed: {
    ...mapGetters([
      'authenticated'
    ])
  },
}
</script>
