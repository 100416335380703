<template>
  <el-input v-model="searchWords" @keyup.enter="search">
    <template #prepend>
      <el-select v-model="searchType" placeholder="类型">
        <el-option label="_id" value="podId"></el-option>
        <el-option label="name" value="name"></el-option>
        <el-option label="子域名" value="subdomain"></el-option>
        <el-option label="userId" value="userId"></el-option>
      </el-select>
    </template>

    <template #append>
      <el-button :loading="getting" :icon="SearchIcon" @click="search"></el-button>
    </template>
  </el-input>

  <el-button
    :loading="starting"
    @click="startSelectedServers">启动</el-button>
  <el-button
    :loading="stopping"
    @click="stopSelectedServers">停止</el-button>
  <el-button
    :loading="getting"
    @click="get">重新获取</el-button>
  <el-table
    :data="servers"
    @selection-change="handleChange">
    <el-table-column type="selection"></el-table-column>
    <el-table-column prop="_id" label="id"></el-table-column>
    <el-table-column label="name">
      <template #default="scope">
        <el-button
          type="text"
          @click="viewServer(scope.row, scope.$index)">
          {{ scope.row.name }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column prop="autoStart" label="autostart"></el-table-column>
    <el-table-column prop="status" label="status"></el-table-column>
    <el-table-column label="software">
      <template #default="scope">
        <span v-if="scope.row.software">
          {{ scope.row.software.type }}-{{ scope.row.software.version }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="planName" label="plan"></el-table-column>
    <el-table-column label="createdAt">
      <template #default="scope">
        {{ new Date(scope.row.createdAt).toLocaleString() }}
      </template>
    </el-table-column>
    <el-table-column label="lastStoppedAt">
      <template #default="scope">
        {{ new Date(scope.row.lastStoppedAt).toLocaleString() }}
      </template>
    </el-table-column>
    <el-table-column min-width="190" label="operation">
      <template #default="scope">
        <el-button-group>
          <el-button
            @click="openExternal(`https://pixelcloud.cn/servers/${scope.row._id}`)">View</el-button>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:currentPage="page"
    layout="prev, pager, next"
    :page-size="10"
    @prev-click="page--"
    @next-click="page++"
    :total="1000">
  </el-pagination>

  <el-dialog
    v-if="viewingServer.name"
    width="90%"
    v-model="showServerDialog"
    :title="viewingServer.name">
    <el-tabs v-model="serverDialogTabName">
      <el-tab-pane name="log" label="Log">
        <span v-if="viewingServer.gettingLog">加载中...</span>
        <pre v-else>{{ log }}</pre>
      </el-tab-pane>

      <el-tab-pane name="crashreport" label="Latest Crashreport">
        <h3>{{ crashreportTitle }}</h3>
        <span v-if="viewingServer.gettingCrashReport">加载中...</span>
        <pre v-else>{{ crashreport }}</pre>
      </el-tab-pane>

      <el-tab-pane name="serverprops" label="server.properties">
        <span v-if="viewingServer.gettingProps">加载中...</span>
        <pre v-else>{{ serverprops }}</pre>
      </el-tab-pane>

      <el-tab-pane name="extensions" label="Extensions">
        <el-input
          v-model="newExtensionId"
          placeholder="extension Id">
          <template #append>
            <el-button
              :loading="viewingServer.addingExtension"
              @click="addExtension(viewingServer, newExtensionId)">添加</el-button>
          </template>
        </el-input>
        <el-table :data="viewingServer.modDeps">
          <el-table-column label="_id">
            <template #default="scope">
              {{ scope.row }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="_id" label="_id"></el-table-column>
          <el-table-column prop="name" label="name"></el-table-column>
          <el-table-column prop="type" label="type"></el-table-column>
          <el-table-column prop="version" label="version"></el-table-column> -->
          <el-table-column label="action">
            <template #default="scope">
              <el-button
                type="danger"
                :loading="viewingServer.removingExtension"
                @click="removeExtension(viewingServer, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { onMounted, ref, watch, computed } from 'vue'
import { showError, showSuccess, openExternal } from '@/utils'
import { Search as SearchIcon } from '@element-plus/icons'
import {
  getAllPods,
  startServer,
  stopServer,
  getFileContent,
  listDir,
  addExtensions,
  removeExtensions,
  searchPods,
} from '@/api'

export default {
  setup() {
    const servers = ref([])
    const page = ref(1)
    const starting = ref(false)
    const stopping = ref(false)
    const selectedServers = ref([])
    const showCrashreport = ref(false)
    const showLog = ref(false)
    const crashreport = ref('')
    const log = ref('')
    const serverprops = ref('')
    const crashreportTitle = ref('')
    const viewingServerIndex = ref(0)
    const showServerDialog = ref(false)
    const serverDialogTabName = ref('log')
    const newExtensionId = ref('')
    const getting = ref(false)
    const searchType = ref('podId')
    const searchWords = ref('')

    const viewingServer = computed(() => servers.value[viewingServerIndex.value] || {})

    watch(page, () => {
      get()
    })

    watch(showServerDialog, () => {
      if (!showServerDialog.value) {
        log.value = ''
        crashreport.value = ''
        crashreportTitle.value = ''
        serverprops.value = ''
      }
    })

    const viewServer = function (server, index) {
      viewingServerIndex.value = index
      showServerDialog.value = true
      getCrashReport(server)
      getLog(server)
      getServerprops(server)
    }

    const handleChange = function (value) {
      selectedServers.value = value
    }

    const startSelectedServers = async function () {
      try {
        starting.value = true
        await start(selectedServers.value)
        await get()
      } catch (err) {
        showError(err)
      } finally {
        starting.value = false
      }
    }

    const stopSelectedServers = async function () {
      try {
        stopping.value = true
        await stop(selectedServers.value)
        await get()
      } catch (err) {
        showError(err)
      } finally {
        stopping.value = false
      }
    }

    const start = async function (servers) {
      try {
        servers.forEach(server => server.starting = true)
        const promises = []
        servers.forEach(server => promises.push(startServer(server._id)))
        await Promise.all(promises)
        showSuccess('开启成功')
      } catch (err) {
        showError(err)
      } finally {
        servers.forEach(server => server.starting = false)
      }
    }

    const stop = async function (servers) {
      try {
        servers.forEach(server => server.stopping = true)
        const promises = []
        servers.forEach(server => promises.push(stopServer(server._id)))
        await Promise.all(promises)
        showSuccess('关闭成功')
      } catch (err) {
        showError(err)
      } finally {
        servers.forEach(server => server.stopping = false)
      }
    }

    const get = async function () {
      getting.value = true
      try {
        servers.value = await getAllPods(page.value)
      } catch (err) {
        showError(err)
      } finally {
        getting.value = false
      }
    }

    const search = async function () {
      getting.value = true
      const query = {}
      query[searchType.value] = searchWords.value

      try {
        servers.value = await searchPods(query)
        console.log(servers.value)
      } catch (err) {
        showError(err)
      } finally {
        getting.value = false
      }
    }

    const getCrashReport = async function (server) {
      try {
        server.gettingCrashReport = true
        const { files } = await listDir(server._id, '/crash-reports')
        const filename = files[files.length - 1]

        crashreportTitle.value = filename
        crashreport.value = await getFileContent(server._id, `/crash-reports/${filename}`)
        showCrashreport.value = true
      } catch (err) {
        showError(err)
      } finally {
        server.gettingCrashReport = false
      }
    }

    const getLog = async function (server) {
      try {
        server.gettingLog = true
        log.value = await getFileContent(server._id, '/logs/latest.log')
        showLog.value = true
      } catch (err) {
        showError(err)
      } finally {
        server.gettingLog = false
      }
    }

    const getServerprops = async function (server) {
      try {
        server.gettingProps = true
        serverprops.value = await getFileContent(server._id, '/server.properties')
      } catch (err) {
        showError(err)
      } finally {
        server.gettingProps = false
      }
    }

    const addExtension = async function (server, extensionId) {
      try {
        server.addingExtension = true
        await addExtensions(server._id, [extensionId])
        await get()
        showSuccess('添加成功')
      } catch (err) {
        showError(err)
      } finally {
        server.addingExtension = false
      }
    }

    const removeExtension = async function (server, extensionId) {
      try {
        server.removingExtension = true
        await removeExtensions(server._id, [extensionId])
        await get()
        showSuccess('删除成功')
      } catch (err) {
        showError(err)
      } finally {
        server.removingExtension = false
      }
    }

    onMounted(get)

    return {
      startSelectedServers,
      stopSelectedServers,
      starting,
      stopping,
      start,
      stop,
      handleChange,
      servers,
      page,
      crashreport,
      log,
      crashreportTitle,
      viewingServerIndex,
      viewingServer,
      serverprops,
      showServerDialog,
      viewServer,
      serverDialogTabName,
      addExtension,
      removeExtension,
      newExtensionId,
      openExternal,
      getting,
      searchType,
      searchWords,
      search,
      SearchIcon,
      get,
    }
  },
}
</script>

<style scoped>
.el-button-group {
  margin-bottom: 0.5em;
}

pre {
  overflow: scroll;
}
.el-input {
  margin-bottom: 1em;
}

.el-select {
  width: 180px;
}
</style>