<template>
  <div class="container">
    <el-row justify="center" align="middle">
      <el-col :span="16">
        <el-form>
          <el-form-item :label="seeAvailable ? '看可用' : '看不可用'">
            <el-switch v-model="seeAvailable"></el-switch>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6">
        <el-button @click="showNewRedeemForm = true">新建</el-button>
        <el-button @click="showBatchUpdater = true">批量改面额</el-button>
      </el-col>
    </el-row>

    <el-row justify="center" align="middle">
      <el-col :span="18">
        <el-table :data="redeems">
          <el-table-column label="id" prop="_id"></el-table-column>
          <el-table-column label="code" prop="code"></el-table-column>
          <el-table-column label="value">
            <template #default="scope">
              ￥{{ scope.row.extras.value / 100 }}
            </template>
          </el-table-column>
          <el-table-column label="description" prop="description"></el-table-column>
          <el-table-column label="userId" prop="userId"></el-table-column>
          <el-table-column label="redeemTime" prop="redeemTime"></el-table-column>
        </el-table>

        <el-pagination
          v-model:currentPage="page"
          layout="prev, pager, next"
          :page-size="10"
          @prev-click="page--"
          @next-click="page++"
          :total="1000">
        </el-pagination>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="showNewRedeems">
    <pre>{{ newRedeemString }}</pre>
  </el-dialog>

  <el-dialog v-model="showNewRedeemForm">
    <el-form>
      <el-form-item label="value">
        <el-input-number v-model="newRedeemForm.value"></el-input-number>
      </el-form-item>
      <el-form-item label="￥">
        {{ newRedeemForm.value / 100 }}
      </el-form-item>
      <el-form-item label="quantity">
        <el-input-number v-model="newRedeemForm.quantity"></el-input-number>
      </el-form-item>
      <el-form-item label="description">
        <el-input v-model="newRedeemForm.description"></el-input>
      </el-form-item>
      <el-form-item label="本批次每个账号限用一次">
        <el-switch v-model="isOnce"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button :loading="creating" @click="create">新建</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  <el-dialog v-model="showBatchUpdater">
    <el-form>
      <el-form-item label="原先面值">
        <el-input type="number" v-model="oldValue">
          <template #prepend>￥</template>
        </el-input>
      </el-form-item>

      <el-form-item label="修改面值">
        <el-input type="number" v-model="newValue">
          <template #prepend>￥</template>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          :loading="batchUpdating"
          @click="updateValues">批量修改</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { useRedeem } from '@/composables'

export default {
  setup () {
    return {
      ...useRedeem()
    }
  },
}
</script>

<style scoped>

</style>