<template>
  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h3>公网上行流量</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'interRecv',
        function () {
          return `${(this.y / (1024 * 1024)).toFixed(2)}Gb`
        })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>公网下行流量</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'interSend',
        function () {
          return `${(this.y / (1024 * 1024)).toFixed(2)}Gb`
        })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>下载次数</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'getRequest')" />
    </el-col>
  </el-row>

  <el-row justify="center">
    <el-col :span="7" :xl="4">
      <h3>内网下行流量</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'intraSend',
        function () {
          return `${(this.y / (1024 * 1024)).toFixed(2)}Gb`
        })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>内网上行流量</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'intraSend',
        function () {
          return `${(this.y / (1024 * 1024)).toFixed(2)}Gb`
        })" />
    </el-col>

    <el-col :span="7" :xl="4">
      <h3>上传次数</h3>
      <Chart :options="getChartOptions(
        oss.datapoints,
        'putRequest')" />
    </el-col>
  </el-row>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { getChartOptionsFactory } from '@/utils'
import { computed } from 'vue'

export default {
  props: {
    oss: {
      type: Object,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
    },
    intervalTag: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const getChartOptions = computed(() => {
      return getChartOptionsFactory(props.startTime, props.intervalTag)
    })

    return {
      getChartOptions,
    }
  },
  components: {
    Chart,
  }
}
</script>

<style scoped>

</style>