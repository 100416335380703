<template>
  <el-form>
    <el-form-item label="帐号">
      <el-input v-model="username"></el-input>
    </el-form-item>
    <el-form-item label="密码">
      <el-input type="password" v-model="password"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button :loading="loading" @click="submit">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ref } from 'vue'
import { login } from '@/api'
import { useStore } from 'vuex'
import localforage from 'localforage'
import { showError } from '@/utils'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const username = ref('')
    const password = ref('')
    const loading = ref(false)
    const store = useStore()
    const router = useRouter()

    const submit = async function () {
      loading.value = true
      try {
        const { accessToken } = await login(username.value, password.value)
        store.commit('update_authenticated', true)
        store.commit('update_token', accessToken)
        await localforage.setItem('pixelcloud-admin-token', accessToken)
        router.push('/index')
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        loading.value = false
      }
    }

    return {
      username,
      password,
      submit,
      loading
    }
  },
}
</script>

<style scoped>

</style>