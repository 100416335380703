import { createStore } from 'vuex'

const store = {
  state: {
    authenticated: false,
    token: '',
  },
  getters: {
    authenticated: state => state.authenticated
  },
  mutations: {
    'update_authenticated' (state, authenticated) {
      state.authenticated = authenticated
    },
    'update_token' (state, token) {
      state.token = token
    }
  },
  actions: {
  },
  modules: {
  }
}

export default createStore(store)
