<template>
  <el-row justify="center">
    <el-col :span="22">
      <h2>
        最近一分钟
        <el-button @click="getLatest" :loading="loading">刷新</el-button>
      </h2>

      <el-collapse v-model="activeNames">
        <el-collapse-item title="管理标签 / 选择展示" name="server-names">
          <div class="server-names">
            <div
              class="server-name"
              v-for="name in displayableNames"
              :key="name">
              <el-button
                :type="displayingNames.includes(name) ? 'primary' : 'default'"
                @click="toggleDisplayName(name)">{{ name }}</el-button>

              <div class="tags" v-if="nameTags[name]">
                <el-tag
                  v-for="tag in nameTags[name]"
                  :key="tag"
                  closable
                  @close="removeNameTag(name, tag)">{{ tag }}</el-tag>
                
                <el-input v-model="newNameTags[name]"></el-input>
                <el-button
                  :disabled="!newNameTags[name]"
                  size="small"
                  @click="addNameTag(name, newNameTags[name])">添加标签</el-button>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <div class="groups">
        <h3>选择标签</h3>
        <el-button
          v-for="group in groups"
          :key="group"
          @click="toggleTagGroup(group)"
          :type="displayingGroups.includes(group) ? 'primary' : 'default'">{{ group }}</el-button>
      </div>

      <div
        class="metrics-container"
        v-for="latestData in filteredLatestDatas"
        :key="latestData.instanceId">
        <h3>{{ latestData.hostname }}</h3>

        <div class="tags">
          <el-tag
            v-for="key in registers[latestData.instanceId]"
            :key="key"
            closable
            @close="removeRegisterKey(latestData.instanceId, key)">{{ key }}</el-tag>

          <el-select v-model="newRegisterKeys[latestData.instanceId]">
            <el-option
              v-for="key in registerableKeys"
              :key="key"
              :label="key"
              :value="key"></el-option>
          </el-select>

          <el-button @click="addRegisterKey(latestData.instanceId, newRegisterKeys[latestData.instanceId])">订阅</el-button>
        </div>

        <div
          class="metric"
          v-for="(value, key) in latestData.datapoint"
          :key="key">
          <div class="metric-title">{{ key }}</div>
          <div v-if="['cpuUsage', 'memoryUsage', 'diskUsage'].includes(key)">
            <el-progress
              :stroke-width="17"
              :text-inside="true"
              :percentage="value.toFixed(2)"></el-progress>
          </div>
          <div v-else-if="[
            'netInRate',
            'netOutRate',
            'ioReadRate',
            'ioWriteRate',
          ].includes(key)">
            {{ (value / 1024).toFixed(2) }}Mb/s
          </div>
          <div v-else>{{ value }}</div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { onMounted, ref, computed, toRaw, watch } from 'vue'
import { getDitingsInfo } from '@/api'
import { showError, parseToDitingServers } from '@/utils'
import localforage from 'localforage'

const registerDefaults = {
  'c259789a-be46-4310-a17c-40fa3ff91899': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  'a0ee3ea7-4bc2-4f94-b7e2-8d93a752b980': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  'c8c61b2e-c388-4a46-bc68-90bedefa6683': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  '9952b736-62c4-4ce7-a6da-a94cd542131a': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  '62e489a3-fcd5-4278-902a-cc97bf6153ef': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  '61e32863-66cf-4ee3-9fc3-793bb17a6beb': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  'dce0a69b-cd00-4e3c-b398-5737ef2865bd': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  'c8667487-34ea-49ed-8592-7365c8c1d2c4': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  '1b8ff4d4-9838-47c1-ae9d-585f0b2032f7': ['cpuUsage', 'memoryUsage', 'diskUsage'],
  'b3ac8463-fac8-4d05-a143-477b038088b2': ['cpuUsage', 'memoryUsage', 'diskUsage'],
}

export default {
  setup () {
    const latestDatas = ref([])
    const loading = ref(false)
    const displayingNames = ref([])
    const displayableNames = ref([])
    const newRegisterKeys = ref({})
    const registerableKeys = ref([])
    const nameTags = ref({})
    const displayingGroups = ref([])
    const registers = ref(registerDefaults)
    const newNameTags = ref({})
    const activeNames = ref([])

    const filteredLatestDatas = computed(() => {
      return latestDatas.value.map(latestData => {
        const data = {}

        for (const key of Object.keys(latestData.datapoint)) {
          if (!registerableKeys.value.includes(key)) {
            registerableKeys.value.push(key)
          }

          if (registers.value[latestData.instanceId] && registers.value[latestData.instanceId].includes(key)) {
            data[key] = latestData.datapoint[key]
          }
        }

        return {
          instanceId: latestData.instanceId,
          hostname: latestData.hostname,
          datapoint: data,
        }
      }).filter(latestData => {
        return displayingNames.value.includes(latestData.hostname)
      })
    })

    const groups = computed(() => {
      const arr = []
      for (const key of Object.keys(nameTags.value)) {
        const tags = nameTags.value[key]
        arr.push(...tags)
      }

      return Array.from(new Set(arr))
    })

    watch(latestDatas, () => {
      latestDatas.value.forEach(latestData => {
        for (const key of Object.keys(latestData.datapoint)) {
          if (!registerableKeys.value.includes(key)) {
            registerableKeys.value.push(key)
          }
        }
      })
    })

    watch(displayingGroups, () => {
      console.log('nimabi')
      const names = []

      for (const tag of displayingGroups.value.values()) {
        for (const key of Object.keys(nameTags.value)) {
          const tags = nameTags.value[key]
          if (tags.includes(tag)) {
            names.push(key)
          }
        }
      }

      console.log(names)

      displayingNames.value = names
    }, {
      deep: true
    })

    const initNameTags = function () {
      latestDatas.value.forEach(latestData => {
        if (!nameTags.value[latestData.hostname]) {
          nameTags.value[latestData.hostname] = ['default']
        }
      })
    }

    const toggleDisplayName = async function (hostname) {
      const index = displayingNames.value.indexOf(hostname)
      if (index !== -1) {
        displayingNames.value.splice(index, 1)
      } else {
        displayingNames.value.push(hostname)
      }
    }

    const removeRegisterKey = async function (instanceId, key) {
      const keys = registers.value[instanceId]
      if (keys) {
        const index = keys.indexOf(key)
        if (index !== -1) {
          keys.splice(index, 1)
        }
      }
      await localforage.setItem('ditings_registers', toRaw(registers.value))
    }

    const addRegisterKey = async function (instanceId, key) {
      const keys = registers.value[instanceId]
      if (keys) {
        if (keys.includes(key)) {
          return
        }

        keys.push(key)
      } else {
        registers.value[instanceId] = [key]
      }
      await localforage.setItem('ditings_registers', toRaw(registers.value))
    }

    const removeNameTag = async function (name, tag) {
      const tags = nameTags.value[name]
      if (tags) {
        if (tags.includes(tag)) {
          const index = tags.indexOf(tag)
          if (index !== -1) {
            tags.splice(index, 1)
          }
        }
      }
      await localforage.setItem('ditings_nameTags', toRaw(nameTags.value))
    }

    const addNameTag = async function (name, tag) {
      const tags = nameTags.value[name]

      if (tags) {
        const index = tags.indexOf(tag)
        if (index === -1) {
          nameTags.value[name].push(tag)
        }
      } else {
        nameTags.value[name] = [tag]
      }
      await localforage.setItem('ditings_nameTags', toRaw(nameTags.value))
    }

    const toggleTagGroup = async function (tag) {
      const index = displayingGroups.value.indexOf(tag)
      if (index === -1) {
        displayingGroups.value.push(tag)
      } else {
        displayingGroups.value.splice(index, 1)
      }
    }

    const getLatest = async function () {
      loading.value = true
      try {
        const endTime = new Date().getTime()
        const startTime = endTime - 2 * 60 * 1000
        const datas = await getDitingsInfo(startTime, endTime, 1)
        const servers = parseToDitingServers(datas)

        const arr = []

        for (const server of servers.values()) {
          const datapoint = server.datapoints[server.datapoints.length - 1]
          if (datapoint) {
            arr.push({
              instanceId: server.instanceId,
              hostname: server.hostname,
              datapoint,
            })
          }
        }

        latestDatas.value = arr

        initNameTags()
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      await getLatest()

      for (const latestData of latestDatas.value.values()) {
        displayableNames.value.push(latestData.hostname)
      }

      const savedRegisters = await localforage.getItem('ditings_registers')
      if (savedRegisters) {
        registers.value = savedRegisters
      }

      const savedNameTags = await localforage.getItem('ditings_nameTags')
      if (savedNameTags) {
        nameTags.value = savedNameTags
      } else {
        initNameTags()
      }
    })

    return {
      loading,
      getLatest,
      filteredLatestDatas,
      toggleDisplayName,
      displayableNames,
      displayingNames,
      addRegisterKey,
      removeRegisterKey,
      registers,
      newRegisterKeys,
      registerableKeys,
      nameTags,
      addNameTag,
      removeNameTag,
      toggleTagGroup,
      groups,
      displayingGroups,
      newNameTags,
      activeNames,
    }
  },
}
</script>

<style scoped>
.metrics-container {
  width: 30%;
  max-width: 23.35em;
  display: inline-block;
  margin: 1em;
  margin-bottom: 1.5em;
}

.metrics-container .el-tag {
  margin-bottom: 1em;
}

.metric-title {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 14px;
}

.tags > * {
  margin-left: 1em;
}

.server-name {
  display: inline-block;
  width: 12em;
  margin: 1em;
}

.server-name .tags > * {
  margin-bottom: 1em;
}

.server-name button {
  margin-bottom: 1em;
}
</style>