<template>
  <div>
    <h1>Storage</h1>

    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="daterange"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select v-model="intervalTag">
          <el-option label="1 分钟" :value="1"></el-option>
          <el-option label="5 分钟" :value="5"></el-option>
          <el-option label="15 分钟" :value="15"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="get" :loading="loading">查询</el-button>
      </el-form-item>
    </el-form>

    <el-tabs v-model="tab" tab-position="left">
      <el-tab-pane
        v-for="oss in osses"
        :key="oss.BucketName"
        :label="oss.BucketName"
        :name="oss.BucketName">
        <HealthStorageDetail
          v-if="tab === oss.BucketName"
          :oss="oss"
          :start-time="startTime"
          :interval-tag="intervalTag" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { getOssInfo } from '@/api'
import { showError } from '@/utils'
import HealthStorageDetail from './HealthStorageDetail.vue'

export default {
  setup () {
    const tab = ref('')
    const datas = ref([])
    const loading = ref(false)
    const intervalTag = ref(1)
    const daterange = ref([])

    const startTime = computed(() => {
      if (daterange.value[0]) {
        return new Date(daterange.value[0]).getTime()
      }

      return new Date().getTime() - 2 * 60 * 60 * 1000
    })

    const endTime = computed(() => {
      if (daterange.value[1]) {
        return new Date(daterange.value[1]).getTime()
      }

      return new Date().getTime()
    })

    const osses = computed(() => {
      const arr = []

      datas.value.forEach(data => {
        data.oss.forEach(oss => {
          let index = -1
          const added = arr.some((elm, i) => {
            if (elm.BucketName === oss.BucketName) {
              index = i
              return true
            }

            return false
          })

          if (!added) {
            arr.push({
              BucketName: oss.BucketName,
              datapoints: []
            })
          } else {
            arr[index].datapoints.push({
              createdAt: data.createdAt,
              ...oss,
            })
          }
        })
      })

      return arr.sort((cur, next) => {
        if (cur.BucketName < next.BucketName) { return -1 }
        if (cur.BucketName > next.BucketName) { return 1 }
        return 0
      })
    })

    const get = async function () {
      loading.value = true
      try {
        datas.value = await getOssInfo(startTime.value, endTime.value, intervalTag.value)
      } catch (err) {
        showError(err)
      } finally {
        loading.value = false
      }
    }

    onMounted(get)

    return {
      osses,
      get,
      loading,
      intervalTag,
      daterange,
      tab,
      startTime,
    }
  },
  components: {
    HealthStorageDetail,
  }
}
</script>